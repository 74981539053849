import { DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Select, Table, Tabs, Tooltip } from "antd";
import * as FileSaver from "file-saver";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getContractorData } from "redux/actions/contractor.action";
import { deleteContractor } from "redux/actions/deleteContractor.action";
import { postNewContractor } from "redux/actions/postNewContractor.action";
import { updateContractor } from "redux/actions/updateContractor.action";
import * as XLSX from "xlsx";
import { getApiArrayDetails, handleTableSort, isValidArray } from "../../../utils/MyMUtils";
import generatePDFContractor from "./generatePdfContractor";

const { Option } = Select;
const { TabPane } = Tabs;

const Contractor = () => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [visibleModal, setVisibleModal] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");
  const [fileName, setFileName] = useState("Contractor Details");

  const dispatch = useDispatch();

  useEffect(() => {
    form.resetFields();
  }, [visibleModal]);

  useEffect(() => {
    form1.resetFields();
  }, [visible]);

  useEffect(() => {
    form2.resetFields();
  }, [displayModal]);

  const postNewContractorRecord = useSelector((state) => {
    return state.postNewContractorInfo.data;
  });

  const updateContractorRecord = useSelector((state) => {
    return state.updateContractorInfo.data;
  });

  const deleteContractInfo = useSelector((state) => {
    return state.deleteContractor.data;
  });

  const contractorList = useSelector((state) => getApiArrayDetails(state.contractot.data));

  useEffect(() => {
    dispatch(getContractorData());
  }, []);

  const updateModalFunction = (elm) => {
    setDefaultValue(elm);
    setVisibleModal(true);
  };

  const exportToCSV = (csvData, fileName) => {
    if (csvData) {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast.error("No Data in Table!");
    }
  };

  const excelData = () => {
    if (isValidArray(contractorList)) {
      return contractorList?.map((item) => {
        return {
          Supplier: item.supplier,
          Service: item.service,
          "Contacted Person": item.contacted_person,
          "Phone Number": item.number,
        };
      });
    }
  };

  const postNewContractorFunction = (data) => {
    dispatch(postNewContractor(data)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getContractorData());
      } else {
        toast.error(record.payload.message);
        dispatch(getContractorData());
      }
    });
    setVisible(false);
  };

  const ViewFunction = (elm) => {
    setDisplayModal(true);
    setDefaultValue(elm);
  };

  const updateContractorFunction = (info) => {
    const putInfo = {
      ...info,
      id: defaultValue.id,
    };
    dispatch(updateContractor(putInfo)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getContractorData());
      } else {
        toast.error(record.payload.message);
        dispatch(getContractorData());
      }
    });
    setVisibleModal(false);
  };

  const deleteFunction = (elm) => {
    dispatch(deleteContractor(elm.id)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getContractorData());
      } else {
        toast.error(record.payload.message);
        dispatch(getContractorData());
      }
    });
  };

  function cancel(e) {}

  const columns = [
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      width: "25%",
      filters: contractorList
        ?.map((f) => {
          return { text: f.supplier, value: f.supplier };
        })
        .sort((a, b) => a.text.toUpperCase().charCodeAt(0) - b.text.toUpperCase().charCodeAt(0)),
      onFilter: (value, record) => record.supplier.includes(value),
      sorter: (a, b) => handleTableSort(a, b, "supplier"),
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      width: "25%",
      sorter: (a, b) => handleTableSort(a, b, "service"),
    },
    {
      title: "Contact Person",
      dataIndex: "contacted_person",
      key: "contacted_person",
      width: "25%",
      sorter: (a, b) => handleTableSort(a, b, "contacted_person"),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "25%",
      render: (_, elm, row) => (
        <div>
          <span className="mr-3">
            <Tooltip title="View" color="rgb(20, 72, 129)">
              <Button onClick={() => ViewFunction(elm)}>
                <EyeOutlined />
              </Button>
            </Tooltip>
          </span>
          <span className="mr-3">
            <Tooltip title="Edit" color="rgb(20, 72, 129)">
              <Button onClick={() => updateModalFunction(elm)}>
                <EditOutlined />
              </Button>
            </Tooltip>
          </span>
          <span className="mr-3">
            <Popconfirm title="Are you sure to delete this data?" onConfirm={() => deleteFunction(elm)} onCancel={() => cancel} okText="Yes" cancelText="No">
              <Tooltip title="Delete" color="rgb(20, 72, 129)">
                <Button>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Form>
        <Button className="style-btn ml-2" onClick={() => setVisible(true)} style={{ float: "right", marginBottom: "20px" }} icon={<PlusCircleOutlined />} shape="round" size="default">
          New Contractor
        </Button>
        <Button shape="round" className="ml-2" style={{ float: "right" }} onClick={() => generatePDFContractor(contractorList, columns)} icon={<DownloadOutlined />}>
          PDF
        </Button>
        <Button shape="round" style={{ float: "right" }} onClick={(e) => exportToCSV(excelData(), fileName)} icon={<DownloadOutlined />}>
          Excel
        </Button>

        <Table columns={columns} loading={!isValidArray(contractorList)} dataSource={contractorList} rowKey="id" />
      </Form>
      <Modal title="New Contractor" centered visible={visible} footer={null} onCancel={() => setVisible(false)} width={900}>
        <Form onFinish={postNewContractorFunction} form={form1} layout="vertical">
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="supplier"
                label="Supplier:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Supplier",
                  },
                ]}
              >
                <Input placeholder="Enter Supplier" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="service"
                label="Service:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Service",
                  },
                ]}
              >
                <Input placeholder="Enter Service" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="contacted_person"
                label="Contacted Person:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Contacted Person",
                  },
                ]}
              >
                <Input placeholder="Enter Contacted Person" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="number"
                label="Phone Number:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Phone number",
                  },
                ]}
              >
                <Input placeholder="Enter Phone number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button className="style-btn" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal title="Edit Contractor" centered visible={visibleModal} footer={null} onCancel={() => setVisibleModal(false)} width={900}>
        <Form onFinish={updateContractorFunction} layout="vertical" initialValues={defaultValue} form={form}>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="supplier"
                label="Supplier:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Supplier",
                  },
                ]}
              >
                <Input placeholder="Enter Supplier" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="service"
                label="Service:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Service",
                  },
                ]}
              >
                <Input placeholder="Enter service" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="contacted_person"
                label="Contacted Person:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Contacted Person",
                  },
                ]}
              >
                <Input placeholder="Enter Contacted Person" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="number"
                label="Phone Number:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Phone number",
                  },
                ]}
              >
                <Input placeholder="Enter Phone number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button className="style-btn" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal title="View Contractor" centered visible={displayModal} footer={null} onCancel={() => setDisplayModal(false)} width={900}>
        <Form layout="vertical" initialValues={defaultValue} form={form2}>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item name="supplier" label="Supplier:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item name="service" label="Service:">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item name="contacted_person" label="Contacted Person:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item name="number" label="Phone Number:">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(Contractor);
