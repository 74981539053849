import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Select, Table } from "antd";
import * as FileSaver from "file-saver";
import moment from "moment";
import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getOutstandingFacility } from "redux/actions/postOutstandingFacility.action";
import * as XLSX from "xlsx";
import { getApiArrayDetails, handleTableSort, isValidArray } from "../../../utils/MyMUtils";

const OutstandingFacility = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const MonthFormat = "MMM YYYY";
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const [fileName, setFileName] = useState("Outstanding Facility Details");
  const [serviceTypeId, setServiceTypeId] = useState("");
  const [monthInfo, setMonthInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [loading, setLoading] = useState(false);

  const facilityList = useSelector((state) => getApiArrayDetails(state.getOutstandingFacility.data));

  const exportToCSV = (csvData, fileName) => {
    if (csvData) {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast.error("No Data in Table!");
    }
  };

  const excelData = () => {
    if (isValidArray(facilityList)) {
      return facilityList?.map((item) => {
        return {
          "Facility Name": item.facility_name,
          "is covid affected": item.is_covid == 1 ? "Covid Affected" : "",
        };
      });
    }
  };

  const column = [
    {
      title: "Facility",
      dataIndex: "facility_name",
      key: "facility_name",
      width: "30%",
      sorter: (a, b) => handleTableSort(a, b, "facility_name"),
      render: (_, record) => {
        return (
          <>
            <span className="mr-2">{record.facility_name}</span>
            {record.is_covid == 1 && <span className="p-2 rounded font-weight-bold text-white bg-danger">Covid Affected</span>}
          </>
        );
      },
    },
  ];

  const handleServiceType = (value) => {
    setLoading(true);
    setServiceTypeId(value);
    const data = {
      service_type: value,
      month: monthInfo,
      year: yearInfo,
    };
    dispatch(getOutstandingFacility(data)).then((record) => {
      if (record.payload.success) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  const handleOnChangeMonth = (date, info) => {
    setLoading(true);
    let month = moment(info).format("MM");
    let year = moment(info).format("YYYY");
    setMonthInfo(month);
    setYearInfo(year);
    const data = {
      service_type: serviceTypeId,
      month: month,
      year: year,
    };
    dispatch(getOutstandingFacility(data)).then((record) => {
      if (record.payload.success) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <Form>
        <Row gutter={24}>
          <Col span={11}></Col>
          <Col span={5}>
            <Form.Item>
              <div>
                <Select onChange={handleServiceType} placeholder="Select Service Type">
                  <Option value={0}>Cleaning</Option>
                  <Option value={1}>Laundry</Option>
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item>
              <div>
                <DatePicker onChange={handleOnChangeMonth} picker="month" style={{ width: "100%" }} format={MonthFormat} />
              </div>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              shape="round"
              // style={{ float: "right" }}
              onClick={(e) => exportToCSV(excelData(), fileName)}
              icon={<DownloadOutlined />}
            >
              Excel
            </Button>
          </Col>
        </Row>

        <Table columns={column} loading={loading} dataSource={facilityList} rowKey="facility_id" />
      </Form>
    </>
  );
};

export default memo(OutstandingFacility);
