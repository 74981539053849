import {
  GET_POLICE_STATEMENT_SUCCESS,
  GET_POLICE_STATEMENT_FAILURE,
} from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const policeStatementSuccess = (data) => {
  return {
    type: GET_POLICE_STATEMENT_SUCCESS,
    payload: data,
  };
};

const policeStatementFailure = (data) => {
  return {
    type: GET_POLICE_STATEMENT_FAILURE,
    payload: data,
  };
};

export const getPoliceStatement = (ids) => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(
      `/get_police_statements?facility_id=${ids}`
    );
    if (!error) {
      return dispatch(policeStatementSuccess(result));
    }
    return dispatch(policeStatementFailure(result));
  };
};
