import { GET_ALL_CLEANING_SUCCESS, GET_ALL_CLEANING_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getAllCleaningSuccess = (data) => {
    return {
        type: GET_ALL_CLEANING_SUCCESS,
        payload: data,
    };
};

const getAllCleaningFailure = (data) => {
    return {
        type: GET_ALL_CLEANING_FAILURE,
        payload: data,
    };
};

export const getAllCleaning = (data, id, date) => {
    return async (dispatch, getState) => {
        const { error, result } = await getApi(`/cleaning_survey_details?fac_id=${id}&survey_date=${date}`, {
            ...data,
        });
        if (!error) {
            return dispatch(getAllCleaningSuccess(result));
        }
        return dispatch(getAllCleaningFailure(result));
    };
};