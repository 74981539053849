import { AppstoreOutlined, ClearOutlined, CommentOutlined, EditOutlined, FieldTimeOutlined, HomeOutlined, ScheduleOutlined, SkinOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AutoLoginLoaderAction } from "redux/actions/autoLoginLoader.action";
import { autoLogin } from "redux/actions/postAutoLogin.action";
import { AutoLoginRedirect, getApiArrayDetails } from "utils/MyMUtils";

export default function Footer() {
  const dispatch = useDispatch();

  const history = useHistory();
  const openPopup = () => {
    document.getElementById("myNav").style.width = "100%";
    document.getElementById("myNav").style.transform = "scale(1)";
  };

  const closeNav = () => {
    // document.getElementById("myNav").style.width = "0%";
    document.getElementById("myNav").style.transform = "scale(0)";
  };

  const redirectDashboard = (value) => {
    localStorage.setItem("user_type", value);
    history.push("/app/dashboards");
    window.location.reload();
  };

  const redirectStaffMaster = (value) => {
    localStorage.setItem("user_type", value);
    history.push("/app/staff");
    window.location.reload();
  };

  const redirectUserMaster = (value) => {
    localStorage.setItem("user_type", value);
    history.push("/app/user-management");
    window.location.reload();
  };

  const redirectFacilityPortalMaster = (value) => {
    localStorage.setItem("user_type", value);
    history.push("/app/facility-portal");
    window.location.reload();
  };

  const redirectTimesheet = (value) => {
    localStorage.setItem("user_type", value);
    history.push("/app/timesheet");
    window.location.reload();
  };

  const redirectCommunication = (value) => {
    localStorage.setItem("user_type", value);
    history.push("/app/communication-management");
    window.location.reload();
  };

  const cleaningAudit = () => {
    var user_id = localStorage.getItem("user_id");
    const post = {
      module_id: "18",
      user_id: user_id,
    };
    dispatch(AutoLoginLoaderAction({ start: true })).then(() => {
      dispatch(autoLogin(post))
        .then((record) => {
          if (record.payload.success == true) {
            const caUrl = process.env.REACT_APP_URL_CA;
            var token = record.payload.result.token_id;
            AutoLoginRedirect(`${caUrl}/autologin?token=${token}&clientId=${localStorage.getItem("client_id")}`);
          } else {
            toast.error(record.payload.message);
          }
        })
        .finally(() => {
          dispatch(AutoLoginLoaderAction({ stop: true }));
        });
    });
  };

  const laundryAudit = () => {
    var user_id = localStorage.getItem("user_id");

    const post = {
      module_id: "19",
      user_id: user_id,
    };
    dispatch(AutoLoginLoaderAction({ start: true })).then(() => {
      dispatch(autoLogin(post))
        .then(({ payload }) => {
          if (payload.success == true) {
            const laUrl = process.env.REACT_APP_URL_LA;

            const { luser_id, token_id, user_id } = payload.result;

            AutoLoginRedirect(`${laUrl}/autologin?userid=${user_id}&luserid=${luser_id}&token=${token_id}&clientId=${localStorage.getItem("client_id")}`);
          } else {
            toast.error(payload.message);
          }
        })
        .finally(() => {
          dispatch(AutoLoginLoaderAction({ stop: true }));
        });
    });
  };

  const education = () => {
    var user_id = localStorage.getItem("user_id");

    const post = {
      module_id: "20",
      user_id: user_id,
    };
    dispatch(AutoLoginLoaderAction({ start: true })).then(() => {
      dispatch(autoLogin(post))
        .then((record) => {
          if (record.payload.success == true) {
            const eduUrl = process.env.REACT_APP_URL_EDU;
            var token = record.payload.result[0].token_id;
            var userid = record.payload.result[0].user_id;
            AutoLoginRedirect(`${eduUrl}/autologin?token=${token}&userId=${userid}&clientId=${localStorage.getItem("client_id")}`);
          } else {
            toast.error(record.payload.message);
          }
        })
        .finally(() => {
          dispatch(AutoLoginLoaderAction({ stop: true }));
        });
    });
  };

  const periodical = () => {
    var user_id = localStorage.getItem("user_id");

    const post = {
      module_id: "2",
      user_id: user_id,
    };
    dispatch(AutoLoginLoaderAction({ start: true })).then(() => {
      dispatch(autoLogin(post))
        .then((record) => {
          if (record.payload.success == true) {
            const periodicalUrl = process.env.REACT_APP_URL_PERIODICAL;
            var token = record.payload.result.token_id;
            var userid = record.payload.result.user_id;
            AutoLoginRedirect(`${periodicalUrl}/autologin?token=${token}&userid=${userid}&clientId=${localStorage.getItem("client_id")}`);
          } else {
            toast.error(record.payload.message);
          }
        })
        .finally(() => {
          dispatch(AutoLoginLoaderAction({ stop: true }));
        });
    });
  };

  const loginDetail = useSelector((state) => getApiArrayDetails(state.userLogin.data));

  return (
    <footer className="footer">
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}. All rights reserved. Powered by <span className="font-weight-semibold">ASEPSIS</span>
        <img src="/img/asespsis_icon.png" alt="" className="img-fluid" style={{ width: "15px", margin: "0 0 0 7px" }} />
      </span>

      <div>
        <Button className="global_navigation" onClick={openPopup} icon={<AppstoreOutlined style={{ fontSize: "22px" }} />}></Button>
      </div>
      <div id="myNav" className="overlay" style={{ width: "100%", transform: "scale(0)", transformOrigin: "96% 93%" }}>
        <a href="javascript:;" className="closebtn" onClick={closeNav} style={{ marginTop: "80px" }}>
          &times;
        </a>
        <div className="overlay-content">
          <div className="modal_container">
            <Row gutter={16} className="justify-content-center justify-content-md-start ml-0 mr-0">
              <Col xs={20} md={8} lg={6}>
                <a onClick={() => redirectDashboard("A")}>
                  {localStorage.getItem("user_type") === "A" ? (
                    <Card style={{ backgroundColor: "#e3f1ff" }} className="card_style" hoverable={true}>
                      <Row>
                        <Col span={6}>
                          <UserOutlined
                            style={{
                              fontSize: "16px",
                              color: "rgb(20, 72, 129)",
                            }}
                          />
                        </Col>
                        <Col>
                          <h4
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Admin
                          </h4>
                          <p>Admin Module</p>
                        </Col>
                      </Row>
                    </Card>
                  ) : (
                    <Card className="card_style" hoverable={true}>
                      <Row>
                        <Col span={6}>
                          <UserOutlined
                            style={{
                              fontSize: "16px",
                              color: "rgb(20, 72, 129)",
                            }}
                          />
                        </Col>
                        <Col>
                          <h4
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Admin
                          </h4>
                          <p>Admin Module</p>
                        </Col>
                      </Row>
                    </Card>
                  )}
                </a>
              </Col>
              <Col xs={20} md={8} lg={6}>
                <a onClick={() => redirectStaffMaster("H")}>
                  {localStorage.getItem("user_type") === "H" ? (
                    <Card style={{ backgroundColor: "#e3f1ff" }} className="card_style" hoverable={true}>
                      <Row>
                        <Col span={6}>
                          <UserOutlined
                            style={{
                              fontSize: "16px",
                              color: "rgb(20, 72, 129)",
                            }}
                          />
                        </Col>
                        <Col>
                          <h4
                            style={{
                              textAlign: "left",
                            }}
                          >
                            HR
                          </h4>
                          <p>HR Module</p>
                        </Col>
                      </Row>
                    </Card>
                  ) : (
                    <Card className="card_style" hoverable={true}>
                      <Row>
                        <Col span={6}>
                          <UserOutlined
                            style={{
                              fontSize: "16px",
                              color: "rgb(20, 72, 129)",
                            }}
                          />
                        </Col>
                        <Col>
                          <h4
                            style={{
                              textAlign: "left",
                            }}
                          >
                            HR
                          </h4>
                          <p>HR Module</p>
                        </Col>
                      </Row>
                    </Card>
                  )}
                </a>
              </Col>
              <Col xs={20} md={8} lg={6}>
                <a onClick={() => redirectUserMaster("U")}>
                  {localStorage.getItem("user_type") === "U" ? (
                    <Card style={{ backgroundColor: "#e3f1ff" }} className="card_style" hoverable={true}>
                      <Row>
                        <Col span={6}>
                          <TeamOutlined
                            style={{
                              fontSize: "16px",
                              color: "rgb(20, 72, 129)",
                            }}
                          />
                        </Col>
                        <Col>
                          <h4
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Users
                          </h4>
                          <p>User Management</p>
                        </Col>
                      </Row>
                    </Card>
                  ) : (
                    <Card className="card_style" hoverable={true}>
                      <Row>
                        <Col span={6}>
                          <TeamOutlined
                            style={{
                              fontSize: "16px",
                              color: "rgb(20, 72, 129)",
                            }}
                          />
                        </Col>
                        <Col>
                          <h4
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Users
                          </h4>
                          <p>User Management</p>
                        </Col>
                      </Row>
                    </Card>
                  )}
                </a>
              </Col>
              <Col xs={20} md={8} lg={6}>
                <a onClick={() => redirectFacilityPortalMaster("F")}>
                  {localStorage.getItem("user_type") === "F" ? (
                    <Card style={{ backgroundColor: "#e3f1ff" }} className="card_style" hoverable={true}>
                      <Row>
                        <Col span={6}>
                          <HomeOutlined
                            style={{
                              fontSize: "16px",
                              color: "rgb(20, 72, 129)",
                            }}
                          />
                        </Col>
                        <Col>
                          <h4
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Facility
                          </h4>
                          <p>Facility Management</p>
                        </Col>
                      </Row>
                    </Card>
                  ) : (
                    <Card className="card_style" hoverable={true}>
                      <Row>
                        <Col span={6}>
                          <HomeOutlined
                            style={{
                              fontSize: "16px",
                              color: "rgb(20, 72, 129)",
                            }}
                          />
                        </Col>
                        <Col>
                          <h4
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Facility
                          </h4>
                          <p>Facility Management</p>
                        </Col>
                      </Row>
                    </Card>
                  )}
                </a>
              </Col>

              <Col xs={20} md={8} lg={6}>
                <a onClick={() => redirectTimesheet("T")}>
                  {localStorage.getItem("user_type") === "T" ? (
                    <Card style={{ backgroundColor: "#e3f1ff" }} className="card_style" hoverable={true}>
                      <Row>
                        <Col span={6}>
                          <FieldTimeOutlined
                            style={{
                              fontSize: "16px",
                              color: "rgb(20, 72, 129)",
                            }}
                          />
                        </Col>
                        <Col>
                          <h4
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Timesheet
                          </h4>
                          <p>Timesheet Module</p>
                        </Col>
                      </Row>
                    </Card>
                  ) : (
                    <Card className="card_style" hoverable={true}>
                      <Row>
                        <Col span={6}>
                          <FieldTimeOutlined
                            style={{
                              fontSize: "16px",
                              color: "rgb(20, 72, 129)",
                            }}
                          />
                        </Col>
                        <Col>
                          <h4
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Timesheet
                          </h4>
                          <p>Timesheet Module</p>
                        </Col>
                      </Row>
                    </Card>
                  )}
                </a>
              </Col>
              <Col xs={20} md={8} lg={6}>
                <a onClick={() => redirectCommunication("C")}>
                  {localStorage.getItem("user_type") === "C" ? (
                    <Card style={{ backgroundColor: "#e3f1ff" }} className="card_style" hoverable={true}>
                      <Row>
                        <Col span={6}>
                          <CommentOutlined
                            style={{
                              fontSize: "16px",
                              color: "rgb(20, 72, 129)",
                            }}
                          />
                        </Col>
                        <Col>
                          <h4
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Communication
                          </h4>
                          <p>Communication Management</p>
                        </Col>
                      </Row>
                    </Card>
                  ) : (
                    <Card className="card_style" hoverable={true}>
                      <Row>
                        <Col span={6}>
                          <CommentOutlined
                            style={{
                              fontSize: "16px",
                              color: "rgb(20, 72, 129)",
                            }}
                          />
                        </Col>
                        <Col>
                          <h4
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Communication
                          </h4>
                          <p>Communication Management</p>
                        </Col>
                      </Row>
                    </Card>
                  )}
                </a>
              </Col>

              {localStorage.getItem("module_id") && localStorage.getItem("module_id").includes(18) ? (
                <Col xs={20} md={8} lg={6}>
                  <Card onClick={() => cleaningAudit()} className="card_style" hoverable={true}>
                    <Row>
                      <Col span={6}>
                        <ClearOutlined style={{ fontSize: "16px", color: "rgb(20, 72, 129)" }} />
                      </Col>
                      <Col>
                        <h4
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Cleaning Audit
                        </h4>
                        <p>Cleaning Management</p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ) : (
                ""
              )}
              {localStorage.getItem("module_id").includes(19) ? (
                <Col xs={20} md={8} lg={6}>
                  <Card className="card_style" onClick={() => laundryAudit()} hoverable={true}>
                    <Row>
                      <Col span={6}>
                        <SkinOutlined style={{ fontSize: "16px", color: "rgb(20, 72, 129)" }} />
                      </Col>
                      <Col>
                        <h4
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Laundry Audit
                        </h4>
                        <p>Laundry Management</p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ) : (
                ""
              )}
              {localStorage.getItem("module_id").includes(20) ? (
                <Col xs={20} md={8} lg={6}>
                  <Card className="card_style" onClick={() => education()} hoverable={true}>
                    <Row>
                      <Col span={6}>
                        <EditOutlined style={{ fontSize: "16px", color: "rgb(20, 72, 129)" }} />
                      </Col>
                      <Col>
                        <h4
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Education | Toolbox
                        </h4>
                        <p>Education Management</p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ) : (
                ""
              )}
              {localStorage.getItem("module_id").includes(2) ? (
                <Col xs={20} md={8} lg={6}>
                  <Card className="card_style" onClick={() => periodical()} hoverable={true}>
                    <Row>
                      <Col span={6}>
                        <ScheduleOutlined style={{ fontSize: "16px", color: "rgb(20, 72, 129)" }} />
                      </Col>
                      <Col>
                        <h4
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Periodical
                        </h4>
                        <p>Periodical Management</p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </div>
        </div>
      </div>
    </footer>
  );
}
