import { POST_NEW_MONTHLY_EDUCATION_SUCCESS, POST_NEW_MONTHLY_EDUCATION_FAILURE } from "../constants/MyM";
import { postApi } from "../../utils/apiCall";

const postMonthlyEducationSuccess = (data) => {
    return {
        type: POST_NEW_MONTHLY_EDUCATION_SUCCESS,
        payload: data,
    };
};

const postMonthlyEducationFailure = (data) => {
    return {
        type: POST_NEW_MONTHLY_EDUCATION_FAILURE,
        payload: data,
    };
};

export const postNewMonthlyEducation = (data) => {
    return async (dispatch, getState) => {
        const { error, result } = await postApi(`/monthly_education_details`, {
            ...data,
        });
        if (!error) {
            return dispatch(postMonthlyEducationSuccess(result));
        }
        return dispatch(postMonthlyEducationFailure(result));
    };
};
