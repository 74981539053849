import { message, notification } from "antd";
import axios from "axios";
import { API_DEVELOPMENT, API_PRODUCTION } from "constants/ApiConstant";
import { CLIENT_ID, MYM_WEB_LOGIN, TOKEN } from "constants/ApiEndPointsConstants";
import { toast } from "react-toastify";
import { changeFileLoadingState } from "../redux/actions/fileLoader.action";
import store from "../redux/store/index";

export const isValidArray = (arr) => {
  return arr && Array.isArray(arr) && arr.length > 0;
};

export const isValidObject = (obj) => {
  return obj && Object.keys(obj).length > 0;
};

export const getQueryParams = (location, value) => {
  const params = new URLSearchParams(location.search);
  return params.get(value);
};

export const getUserId = () => {
  return localStorage.getItem("user_id");
};

export const getUserType = () => {
  return localStorage.getItem("user_type");
};

export const getUserToken = () => {
  return localStorage.getItem("token");
};

export const isValidToken = () => {
  return localStorage.getItem("token") === "afa1e8834ce1943ad5ee6c1e38936dfc";
};

export const setUrl = () => {
  const devUrl = process.env.REACT_APP_URL_DEV;
  const prodUrl = process.env.REACT_APP_URL_PROD;
  const node_env = process.env.REACT_APP_NODE_ENV;

  if (node_env === API_DEVELOPMENT) {
    return devUrl;
  } else if (node_env === API_PRODUCTION) {
    return prodUrl;
  } else {
    return;
  }
};

export const setUrl2 = () => {
  const devUrl = process.env.REACT_APP_URL_PROD;
  const prodUrl = process.env.REACT_APP_URL_PROD;
  const node_env = process.env.REACT_APP_NODE_ENV;
  return node_env === API_DEVELOPMENT ? devUrl : prodUrl;
};

export const getApiSingleDetails = (data, defaultReturn = null) => {
  if (data && data.result && isValidObject(data.result)) {
    return data.result;
  }
  return defaultReturn;
};

export const getApiArrayDetails = (data, defaultReturn = null) => {
  if (data && data.result && Array.isArray(data.result) && data.result.length > 0) {
    return data.result;
  }

  return defaultReturn;
};

export const getApiArrayFirstDetails = (data, defaultReturn = null) => {
  if (data && data.result && Array.isArray(data.result) && data.result.length > 0 && data.result[0]) {
    return data.result[0];
  }

  return defaultReturn;
};

export const apiToastSuccess = (data) => {
  if (data && data.data && data.data.message) {
    toast.success(data.result.message);
  }
};

export const toastWarning = (message) => {
  if (message) {
    toast.warning(message);
  }
};

export const apiToastError = (error) => {
  if (error && error.response && error.response.data && error.response.data.message) {
    toast.error(error.response.data.message);
  } else if (error && error.message) {
    toast.error(error.message);
  }
};

export const GetValidDataFromResponse = (response) => {
  return response.payload;
};

export const getPreSignedUrl = async (pdf) => {
  store.dispatch(changeFileLoadingState(pdf));

  await axios
    .post(`${process.env.REACT_APP_API_URL}/get_presigned_url`, { url: pdf }, { headers: { ...getAllRequiredHeaders({ ru: "get_presigned_url" }) } })
    .then((response) => {
      const { data } = response;
      if (data.success) {
        store.dispatch(changeFileLoadingState(""));
        window.open(data.result, "_blank");
      }
    })
    .catch((error) => {
      store.dispatch(changeFileLoadingState(""));
      message.error("Something went wrong");
    });
};

export const sendNotification = ({ notificationType, message, duration }) => {
  const options = {
    progress: 0,
    autoClose: duration || 3000,
  };

  if (notificationType === "error") {
    toast.error(message, options);
  } else if (notificationType === "default") {
    toast(message, options);
  } else if (notificationType === "warning") {
    toast.warning(message, options);
  } else if (notificationType === "success") {
    toast.success(message, options);
  }
};

export const AutoLoginRedirect = (url) => {
  let popUp = window.open(url, "_blank");
  if (popUp == null || typeof popUp == "undefined") {
    notification["warning"]({
      message: "Please disable your pop-up blocker and try again.",
      duration: 3,
    });
  } else {
    popUp.focus();
  }
};

export const getAllRequiredHeaders = ({ ru }) => {
  // ru = request URL
  let headers = {};

  headers["origin-type"] = process.env.REACT_APP_ORIGIN_TYPE;
  headers["origin-from"] = process.env.REACT_APP_ORIGIN_FROM;
  headers["device-type"] = process.env.REACT_APP_DEVICE_TYPE;

  if (ru === MYM_WEB_LOGIN) {
    headers["token-id"] = 0;
  } else {
    headers["token-id"] = localStorage.getItem(TOKEN);
    headers["client-id"] = localStorage.getItem(CLIENT_ID);
  }

  return {
    ...headers,
  };
};

export const getBase64URI = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result;
      resolve({ base64String });
    };
    reader.onerror = (error) => reject(error);
  });
};

export const handleTableSort = (a, b, key) => {
  if (typeof a[key] === "string" && typeof b[key] === "string") {
    return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
  }
  return a[key] - b[key];
};
