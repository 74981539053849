import { DeleteOutlined, DownloadOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Modal, Popconfirm, Row, Select, Switch, Table, Tooltip } from "antd";
import * as FileSaver from "file-saver";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteCommunicationLogin } from "redux/actions/deleteCommunicationLogin.action";
import { getFacilityInfo } from "redux/actions/getAllFacilityList.action";
import { getCommunicationLogin } from "redux/actions/getCommunicationLogin.action";
import { AddCommunicationLogin } from "redux/actions/postCommunicationLogin.action";
import { UpdateCommunicationLogin } from "redux/actions/updateCommunicationLogin.action";
import * as XLSX from "xlsx";
import { getApiArrayDetails, handleTableSort, isValidArray } from "../../../utils/MyMUtils";

const FacilityLoginAccess = () => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const dispatch = useDispatch();
  const { Option } = Select;

  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [defaultValue, setDefaultValue] = useState([]);
  const [facilityId, setFacilityId] = useState([]);
  const [checked, setChecked] = useState(false);
  const [id, setId] = useState("");
  const [dataValue, setDataValue] = useState([]);
  const [fileName, setFileName] = useState("Facility Login");

  const facilityData = useSelector((state) => getApiArrayDetails(state.getFacilityList.data));

  useEffect(() => {
    dispatch(getFacilityInfo());
  }, []);

  useEffect(() => {
    form.resetFields();
    form1.resetFields();
  }, [visible, editVisible]);

  const getFacilityListDetails = () => {
    if (isValidArray(facilityData)) {
      return facilityData.map((facilityData) => (
        <Option name="facility_id" key={facilityData.facility_id} value={facilityData.facility_id}>
          {facilityData.facility_name}
        </Option>
      ));
    }
  };

  const exportToCSV = (csvData, fileName) => {
    if (csvData) {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast.error("No Data in Table!");
    }
  };

  const excelData = () => {
    if (isValidArray(dataValue)) {
      return dataValue?.map((item) => {
        return {
          "Facility Name": item.facility_name,
          "User Name": item.user_name,
          password: item.password,
        };
      });
    }
  };

  function onChange(checked) {
    setChecked(checked);
  }
  const deleteFunction = (elm) => {
    setLoading(true);
    const PostData = {
      facility_ids: facilityId,
    };
    dispatch(deleteCommunicationLogin(elm.id)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getCommunicationLogin(PostData)).then((record) => {
          if (isValidArray(record.payload.result)) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      } else {
        toast.error(record.payload.message);
        dispatch(getCommunicationLogin(PostData)).then((record) => {
          if (isValidArray(record.payload.result)) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      }
    });
  };

  const EditFunction = (e) => {
    setId(e.id);
    setEditVisible(true);
    setDefaultValue({
      ...e,
    });
  };

  const column = [
    {
      title: "Facility",
      dataIndex: "facility_name",
      key: "facility_name",
      width: "20%",
      sorter: (a, b) => handleTableSort(a, b, "facility_name"),
    },
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
      width: "20%",
      sorter: (a, b) => handleTableSort(a, b, "user_name"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "20%",
      render: (_, elm) => (
        <div>
          <span className="mr-3">
            <Tooltip title="Edit" color="rgb(20, 72, 129)">
              <Button onClick={() => EditFunction(elm)}>
                <EditOutlined />
              </Button>
            </Tooltip>
          </span>
          <span className="mr-3">
            <Popconfirm title="Are you sure to delete this data?" onConfirm={() => deleteFunction(elm)} onCancel={() => cancel} okText="Yes" cancelText="No">
              <Tooltip title="Delete" color="rgb(20, 72, 129)">
                <Button>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </span>
        </div>
      ),
    },
  ];

  const cancel = () => {};

  const handleOnChangeFacility = (facility) => {
    setFacilityId(facility);
    setLoading(true);
    const PostData = {
      facility_ids: facility,
    };
    dispatch(getCommunicationLogin(PostData)).then((record) => {
      if (isValidArray(record.payload.result)) {
        const data = record.payload.result;
        const filterData = data
          .filter((item) => item.login_type == "F")
          .map((dataValue) => {
            return dataValue;
          });
        setDataValue(filterData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const PostData = (data) => {
    setLoader(true);
    setLoading(true);
    const PostData = {
      facility_ids: facilityId,
    };

    dispatch(AddCommunicationLogin({ ...data, login_type: "F" })).then((record) => {
      if (record.payload.success) {
        toast.success(record.payload.message);
        setLoader(false);
        setVisible(false);
        dispatch(getCommunicationLogin(PostData)).then((record) => {
          if (isValidArray(record.payload.result)) {
            const data = record.payload.result;
            const filterData = data
              .filter((item) => item.login_type == "F")
              .map((dataValue) => {
                return dataValue;
              });
            setDataValue(filterData);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      } else {
        toast.error(record.payload.message);
        setLoader(false);
        setVisible(false);
        dispatch(getCommunicationLogin(PostData)).then((record) => {
          if (isValidArray(record.payload.result)) {
            const data = record.payload.result;
            const filterData = data
              .filter((item) => item.login_type == "F")
              .map((dataValue) => {
                return dataValue;
              });
            setDataValue(filterData);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      }
    });
  };

  const EditData = (data) => {
    setEditLoader(true);
    setLoading(true);
    const PostData = {
      facility_ids: facilityId,
    };
    dispatch(UpdateCommunicationLogin(id, data)).then((record) => {
      if (record.payload.success) {
        toast.success(record.payload.message);
        setEditLoader(false);
        setEditVisible(false);
        dispatch(getCommunicationLogin(PostData)).then((record) => {
          if (isValidArray(record.payload.result)) {
            const data = record.payload.result;
            const filterData = data
              .filter((item) => item.login_type == "F")
              .map((dataValue) => {
                return dataValue;
              });
            setDataValue(filterData);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      } else {
        toast.error(record.payload.message);
        setEditLoader(false);
        setEditVisible(false);
        dispatch(getCommunicationLogin(PostData)).then((record) => {
          if (isValidArray(record.payload.result)) {
            const data = record.payload.result;
            const filterData = data
              .filter((item) => item.login_type == "F")
              .map((dataValue) => {
                return dataValue;
              });
            setDataValue(filterData);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      }
    });
  };
  return (
    <>
      <Form layout="horizontal">
        <Row gutter={24}>
          <Col span={14}></Col>
          <Col span={5}>
            <Form.Item>
              <div>
                <Select mode="multiple" style={{ width: "100%" }} placeholder="Select Facility" onChange={handleOnChangeFacility} showSearch optionFilterProp="children">
                  {getFacilityListDetails()}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={2}>
            {" "}
            <Button shape="round" style={{ float: "right" }} onClick={(e) => exportToCSV(excelData(), fileName)} icon={<DownloadOutlined />}>
              Excel
            </Button>
          </Col>
          <Col span={3}>
            <Form.Item>
              <div>
                <Button className="style-btn" onClick={() => setVisible(true)} style={{ float: "right" }} icon={<PlusCircleOutlined />} shape="round" size="default">
                  Add Login
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table loading={loading} columns={column} dataSource={dataValue} />

      <Modal title="Add Facility Login" centered visible={visible} footer={null} onCancel={() => setVisible(false)} width={600}>
        <Form onFinish={PostData} layout="vertical" form={form}>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="facility_id"
                className="btn-weekpicker"
                label="Facility"
                rules={[
                  {
                    required: true,
                    message: "Please Select Facility.",
                  },
                ]}
              >
                <Select allowClear placeholder="Select Facility">
                  {getFacilityListDetails()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider>Login Access</Divider>
          <Row gutter={24} className="justify-content-center">
            <Col md={12}>
              <Form.Item
                name="user_name"
                className="btn-weekpicker"
                label="Login ID"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Login ID.",
                  },
                ]}
              >
                <Input placeholder="Login ID" />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="password"
                className="btn-weekpicker"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Password.",
                  },
                ]}
              >
                <Input placeholder="Password" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit" loading={loader}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal title="Edit Facility Login" centered visible={editVisible} footer={null} onCancel={() => setEditVisible(false)} width={400}>
        <Form
          onFinish={EditData}
          // initialValues={defaultValue}
          layout="vertical"
          form={form1}
        >
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="user_name"
                className="btn-weekpicker"
                label="Username"
                initialValue={defaultValue.user_name}
                rules={[
                  {
                    required: true,
                    message: "Please Enter User Name.",
                  },
                ]}
              >
                <Input placeholder="Enter User Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item label="New Password">
                <Switch onChange={onChange} />
              </Form.Item>
            </Col>

            {checked === true ? (
              <>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item className="mt-3" label="Password" name="password">
                    <Input defaultValue="" placeholder="Enter Password" />
                  </Form.Item>
                </Col>
              </>
            ) : null}
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit" loading={editLoader}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default memo(FacilityLoginAccess);
