import { DeleteOutlined, EyeOutlined, MailOutlined, PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, message, Modal, Popconfirm, Popover, Row, Select, Spin, Table, Tooltip, Upload } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getMeetingMinutesData } from "redux/actions/getMeetingMinutes.action";
import { getGroup } from "redux/actions/group.action";
import { postMeetingMinutes } from "redux/actions/postMeetingMinutes.action";
import { uploadCertificate } from "redux/actions/uploadCertificate.action";
import { deleteMeeting } from "../../../redux/actions/deleteMeetingMinutes.action";
import { getApiArrayDetails, getPreSignedUrl, handleTableSort, isValidArray, isValidObject } from "../../../utils/MyMUtils";
import Email from "../mail";

const { Option } = Select;

function MeetingList() {
  const MonthFormat = "MMM YYYY";

  const dispatch = useDispatch();
  const [form1] = Form.useForm();

  const [visibleMail, setVisibleMail] = useState(false);
  const [loader, setLoader] = useState(false);
  const [groupID, setGroupID] = useState("");
  const [addMeetingVisible, setAddMeetingVisible] = useState(false);
  const [uploadStatementLink, setUploadStatementLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [postDate, setPostDate] = useState("");

  const groupList = useSelector((state) => getApiArrayDetails(state.GroupList.data));
  const meetingList = useSelector((state) => getApiArrayDetails(state.getMeetingData.data));

  const fileLoadingState = useSelector((state) => state.fileLoadingReducer.fileLoadingState);

  useEffect(() => {
    setLoading(true);
    let data = {
      org_id: "",
      meeting_date: "",
    };
    dispatch(getGroup());
    dispatch(getMeetingMinutesData(data)).then((record) => {
      if (record.payload.success) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);
  useEffect(() => {
    form1.resetFields();
  }, [addMeetingVisible]);

  const handleOnChangeGroup = (event) => {
    setGroupID(event);
  };

  const handleOnChangeMonth = (date, info) => {
    setLoading(true);
    let dateData = moment(info).format("YYYY-MM");
    let data = {
      org_id: groupID,
      meeting_date: dateData,
    };
    dispatch(getMeetingMinutesData(data)).then((record) => {
      if (record.payload.success) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const getGroupListDetails = () => {
    if (isValidArray(groupList)) {
      return groupList.map((group) => (
        <Option name="state_id" key={group.org_id} value={group.org_id}>
          {group.org_name}
        </Option>
      ));
    }
  };

  function onChange(value, dateString) {
    let passData = moment(dateString).format("MM-YYYY");
    setPostDate(passData);
  }

  function onOk(value) {}

  const setMail = (elm) => {
    setVisibleMail(elm.id);
    if (visibleMail === elm.id) {
      setVisibleMail(false);
    }
  };
  const showModal = () => {
    setAddMeetingVisible(true);
  };
  const closePopover = () => {
    setVisibleMail(false);
  };

  const AddNewMeeting = () => {
    const uploadPdf = {
      org_id: groupID,
      meeting_pdf: uploadStatementLink,
      meeting_date: postDate,
    };
    dispatch(postMeetingMinutes(uploadPdf)).then((record) => {
      if (record.payload.success) {
        dispatch(getMeetingMinutesData()).then(() => {
          setLoading(false);
        });
        setAddMeetingVisible(false);
        toast.success(record.payload.message);
      } else {
        setAddMeetingVisible(false);
        toast.error(record.payload.message);
      }
    });
  };
  const uploadSettings = {
    accept: ".pdf",
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        message.error(`${file.name} is not a pdf file`);
      }
      return false;
    },
    onChange: (info) => {
      let selectedFile = info.fileList;
      let file = null;
      let fileName;
      if (selectedFile.length > 0) {
        let fileToLoad = selectedFile[selectedFile.length - 1].originFileObj;
        fileName = fileToLoad.name;
        let reader = new FileReader();
        reader.onload = function (fileEvent) {
          file = fileEvent.target.result;
          let name = fileName.split(".");
          const uploadInfo = {
            file: file,
            filename: name[0],
            certificate_type: "meeting-minutes",
          };
          dispatch(uploadCertificate(uploadInfo)).then((record) => {
            if (isValidObject(record.payload.result)) {
              setUploadStatementLink(record?.payload?.result?.result);
            }
          });
        };
        reader.readAsDataURL(fileToLoad);
      }
    },
  };

  function confirm(elm) {
    dispatch(deleteMeeting(elm.id)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getMeetingMinutesData()).then(() => {
          setLoading(false);
        });
      } else {
        toast.error(record.payload.message);
        dispatch(getMeetingMinutesData()).then(() => {
          setLoading(false);
        });
      }
    });
  }
  function cancel(e) {}

  const columns = [
    {
      title: "Organization",
      dataIndex: "org_name",
      key: "org_name",
      width: "25%",
      sorter: (a, b) => handleTableSort(a, b, "org_name"),
    },
    {
      title: "Meeting Date",
      dataIndex: "meeting_date",
      key: "meeting_date",
      width: "20%",
      render: (elm) => {
        return moment(elm, "YYYY-MM-DD").format("MMMM YYYY");
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "30%",
      render: (_, elm) => (
        <div>
          <span className="mr-3">
            <Tooltip title="View" color="rgb(20, 72, 129)">
              {meetingList
                .filter((item) => elm.id === item.id)
                .map((item) => {
                  return item?.pdf_link ? (
                    <Button onClick={() => getPreSignedUrl(item.pdf_link)}>{fileLoadingState !== item.pdf_link ? <EyeOutlined /> : <Spin size="small" />}</Button>
                  ) : (
                    <a target="_blank" href="">
                      <Button disabled>
                        <EyeOutlined style={{ color: "#00000040" }} />
                      </Button>
                    </a>
                  );
                })}
            </Tooltip>
          </span>
          <span className="mr-3">
            {meetingList
              .filter((item) => elm.id == item.id)
              .map((item) => {
                return item?.pdf_link ? (
                  <Popover title="Additional Emails" content={<Email passData={elm} visibleMailprop={closePopover} />} visible={elm.id === visibleMail} trigger="click">
                    <Tooltip title="Mail" color="rgb(20, 72, 129)">
                      <Button onClick={() => setMail(elm)}>
                        <MailOutlined />
                      </Button>
                    </Tooltip>
                  </Popover>
                ) : (
                  <Tooltip title="Mail" color="rgb(20, 72, 129)">
                    <Button disabled>
                      <MailOutlined style={{ color: "#00000040" }} />
                    </Button>
                  </Tooltip>
                );
              })}
          </span>

          <span className="mr-3">
            <Popconfirm title="Are you sure to delete this data?" onConfirm={() => confirm(elm)} onCancel={() => cancel} okText="Yes" cancelText="No">
              <Tooltip title="Delete" color="rgb(20, 72, 129)">
                <Button>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Form layout="horizontal">
        <Row gutter={24}>
          <Col span={10}></Col>
          <Col span={5}>
            <Form.Item>
              <div>
                <Select allowClear onChange={handleOnChangeGroup} placeholder="Select Group">
                  {getGroupListDetails()}
                </Select>
              </div>
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item name="demo3">
              <DatePicker onChange={handleOnChangeMonth} picker="month" style={{ width: "100%" }} format={MonthFormat} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button className="style-btn" onClick={showModal} style={{ float: "right", marginBottom: "20px" }} icon={<PlusCircleOutlined />} shape="round">
              New Meeting
            </Button>
          </Col>
        </Row>
      </Form>
      <Table loading={loading} columns={columns} dataSource={isValidArray(meetingList) ? meetingList : ""} rowKey="id" />
      <Modal title="Add Meeting" centered visible={addMeetingVisible} footer={null} onCancel={() => setAddMeetingVisible(false)} width={700}>
        <Form layout="vertical" onFinish={AddNewMeeting} form={form1}>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="org_id"
                label="Select Group:"
                rules={[
                  {
                    required: true,
                    message: "Please Select Group Name",
                  },
                ]}
              >
                <Select allowClear onChange={handleOnChangeGroup} placeholder="Select Group">
                  {getGroupListDetails()}
                </Select>
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="date"
                label="Select Month:"
                rules={[
                  {
                    required: true,
                    message: "Please Select Date",
                  },
                ]}
              >
                <DatePicker format={MonthFormat} picker="month" onChange={onChange} onOk={onOk} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="meeting_pdf"
                label="File:"
                rules={[
                  {
                    required: true,
                    message: "Please Upload File",
                  },
                ]}
              >
                <Upload {...uploadSettings} maxCount={1}>
                  <Button onClick={() => setAddMeetingVisible(true)}>
                    <UploadOutlined />
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col md={10} xs={16}></Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit" loading={loader}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default MeetingList;
