import { DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, Table, Tabs, Tooltip } from "antd";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getApiArrayDetails, handleTableSort, isValidArray } from "../../../utils/MyMUtils";
import "../index.css";
// import { uploadStatement } from "redux/actions/uploadStatement.action";
import { PlusCircleOutlined } from "@ant-design/icons";
import * as FileSaver from "file-saver";
import { deleteVehical } from "redux/actions/deleteVehical.action";
import { getUserList } from "redux/actions/getUserList.action";
import { postNewVehical } from "redux/actions/postVehical.action";
import { getStateList } from "redux/actions/stateList.action";
import { updateVehicle } from "redux/actions/updateVehicle.action";
import { getVehicleData } from "redux/actions/vehicale.action";
import * as XLSX from "xlsx";
import generatePDFVehicle from "./generatePdfVehicle";

const { Option } = Select;
const { TabPane } = Tabs;

const VehicaleDetail = ({ csvData }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const dateFormat = "DD/MM/YYYY";

  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");
  const [id, setId] = useState("");
  const [value, setValue] = useState("");
  const [time, setTime] = useState();
  const [seeTime, setSeeTime] = useState();
  const [fileName, setFileName] = useState("Vehicle Details");

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();

  const VehicaleList = useSelector((state) => getApiArrayDetails(state.vehicale.data));

  const stateList = useSelector((state) => getApiArrayDetails(state.stateList.data));

  const userList = useSelector((state) => getApiArrayDetails(state.getUserList.data));

  useEffect(() => {
    dispatch(getVehicleData());
  }, []);

  useEffect(() => {
    dispatch(getStateList());
    dispatch(getUserList());
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  useEffect(() => {
    form2.resetFields();
    setSeeTime();
  }, [displayModal]);

  useEffect(() => {
    form3.resetFields();
  }, [visibleModal]);

  const exportToCSV = (csvData, fileName) => {
    if (csvData) {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast.error("No Data in Table!");
    }
  };

  const excelData = () => {
    if (isValidArray(VehicaleList)) {
      return VehicaleList?.map((item) => {
        return {
          State: item.state_name,
          "Vehicle Name": item.vehical_name,
          "Registration Number": item.rego,
          "Exp. Date": moment(item.reg_exp_date).format("DD/MM/YYYY"),
          Driver: item.Driver,
          "Engine Number": item.engine_no,
          Colour: item.colour,
          "Vin Number": item.vin_no,
        };
      });
    }
  };

  function onChange(value, dateString) {
    // moment(value).format("YYYY/MM/DD");
    const variable = moment(value).format("DD/MM/YYYY");
    setValue(variable);
  }

  const ViewFunction = (elm) => {
    setDisplayModal(true);
    setDefaultValue({
      ...elm,
      reg_exp_date: moment(elm.reg_exp_date),
    });
  };

  const updateModalFunction = (elm) => {
    const { reg_exp_date } = elm;
    // let info = moment(reg_exp_date).format(dateFormat);
    setTime(reg_exp_date);
    setDefaultValue(elm);
    setVisibleModal(true);
    setId(elm.id);
  };

  const getStateListDetails = () => {
    if (isValidArray(stateList)) {
      return stateList.map((state) => (
        <Option name="state_id" key={state.state_id} value={state.state_id}>
          {state.state_name}
        </Option>
      ));
    }
  };

  const getUserListDetails = () => {
    if (isValidArray(userList)) {
      return userList.map((user) => (
        <Option name="user_id" key={user.user_id} value={user.user_id}>
          {user.first_name} {user.last_name}
        </Option>
      ));
    }
  };

  const postvehicleFunction = (data) => {
    dispatch(postNewVehical(data)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getVehicleData());
      } else {
        toast.error(record.payload.message);
        dispatch(getVehicleData());
      }
    });
    setVisible(false);
  };

  const updateVehicleFunction = (data) => {
    const putInfo = {
      ...data,
      id: id,
      reg_exp_date: value ? value : time,
    };
    dispatch(updateVehicle(putInfo)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getVehicleData());
      } else {
        toast.error(record.payload.message);
        dispatch(getVehicleData());
      }
    });
    setVisibleModal(false);
  };

  const deleteVehicleFunction = (elm) => {
    dispatch(deleteVehical(elm.id)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getVehicleData());
      } else {
        toast.error(record.payload.message);
        dispatch(getVehicleData());
      }
    });
  };

  const customFormat = (value) => value.format(dateFormat);

  function confirm(elm) {
    // message.success("Click on Yes");
    dispatch(deleteVehical(elm.id)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getVehicleData());
      } else {
        toast.error(record.payload.message);
        dispatch(getVehicleData());
      }
    });
  }

  function cancel(e) {}

  const columns = [
    {
      title: "State",
      dataIndex: "state_name",
      key: "state_name",
      width: "13  %",
      sorter: (a, b) => handleTableSort(a, b, "state_name"),
    },
    {
      title: "Vehicle Name",
      dataIndex: "vehical_name",
      key: "vehical_name",
      width: "25%",
      sorter: (a, b) => handleTableSort(a, b, "vehical_name"),
    },
    {
      title: "Registration Number",
      dataIndex: "rego",
      key: "rego",
      width: "10%",
    },
    {
      title: "Exp. Date",
      key: "reg_exp_date",
      width: "12%",
      render: (text, record) => (text?.reg_exp_date ? `${moment(record?.reg_exp_date).format(dateFormat)}` : null),
      sorter: (a, b) => handleTableSort(a, b, "reg_exp_date"),
    },
    {
      title: "Driver",
      dataIndex: "Driver",
      key: "Driver",
      width: "15%",
      sorter: (a, b) => handleTableSort(a, b, "Driver"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "25%",
      render: (_, elm, row) => (
        <div>
          <span className="mr-3">
            <Tooltip title="View" color="rgb(20, 72, 129)">
              <Button onClick={() => ViewFunction(elm)}>
                <EyeOutlined />
              </Button>
            </Tooltip>
          </span>
          <span className="mr-3">
            <Tooltip title="Edit" color="rgb(20, 72, 129)">
              <Button onClick={() => updateModalFunction(elm)}>
                <EditOutlined />
              </Button>
            </Tooltip>
          </span>
          <span className="mr-3">
            <Popconfirm title="Are you sure to delete this data?" onConfirm={() => confirm(elm)} onCancel={() => cancel} okText="Yes" cancelText="No">
              <Tooltip title="Delete" color="rgb(20, 72, 129)">
                <Button>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </span>
        </div>
      ),
    },
  ];

  function onOk(value) {}

  return (
    <>
      <Form layout="horizontal">
        <Button className="style-btn ml-3" onClick={() => setVisible(true)} style={{ float: "right", marginBottom: "20px" }} icon={<PlusCircleOutlined />} shape="round" size="default">
          New Vehicle
        </Button>
        <Button shape="round" className="ml-3" style={{ float: "right" }} onClick={() => generatePDFVehicle(VehicaleList, columns)} icon={<DownloadOutlined />}>
          PDF
        </Button>
        <Button shape="round" style={{ float: "right" }} onClick={(e) => exportToCSV(excelData(), fileName)} icon={<DownloadOutlined />}>
          Excel
        </Button>
        <Table columns={columns} dataSource={VehicaleList} rowKey="id" />
      </Form>
      <Modal title="Add New Vehicle" centered visible={visible} footer={null} onCancel={() => setVisible(false)} width={1000}>
        <Form onFinish={postvehicleFunction} layout="vertical" form={form}>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="vehical_name"
                label="Vehicle Name:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Vehicle Name",
                  },
                ]}
              >
                <Input placeholder="Enter Vehicle Name" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="rego"
                label="Registration Number:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Registration Number",
                  },
                ]}
              >
                <Input placeholder="Enter Registration Number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="vin_no"
                label="Vin Number:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Vin Number",
                  },
                ]}
              >
                <Input placeholder="Enter Vin Number" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="engine_no"
                label="Engine Number:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Engine Number",
                  },
                ]}
              >
                <Input placeholder="Enter Engine Number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item name="colour" label="Colour:">
                <Input placeholder="Enter Colour" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="reg_exp_date"
                label="Registration Expiry Date:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Registration Expiry Date",
                  },
                ]}
              >
                <DatePicker
                  // defaultValue={moment(moment(), dateFormat)}
                  format={dateFormat}
                  onChange={onChange}
                  onOk={onOk}
                  style={{ width: "100%" }}
                  // disabledDate={(current) => {
                  //   return moment().add(-1, "days") >= current;
                  // }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="state_id"
                label="State:"
                rules={[
                  {
                    required: true,
                    message: "Please Select State",
                  },
                ]}
              >
                <Select>{getStateListDetails()}</Select>
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="user_id"
                label="Driver:"
                rules={[
                  {
                    required: true,
                    message: "Please Select User",
                  },
                ]}
              >
                <Select showSearch optionFilterProp="children">
                  {getUserListDetails()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button className="style-btn" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal title="View Vehicle Details" centered visible={displayModal} footer={null} onCancel={() => setDisplayModal(false)} width={1000}>
        <Form layout="vertical" initialValues={defaultValue} form={form2}>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item name="Driver" label="Driver Name:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item name="vehical_name" label="Vehicle Name:">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item name="rego" label="Registration Number:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item name="engine_no" label="Engine Number:">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item name="colour" label="Colour:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item name="vin_no" label="Vin Number:">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item name="state_name" label="State:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item name="reg_exp_date" label="Registration Expiry Date:">
                <DatePicker format={dateFormat} style={{ width: "100%" }} disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal title="Edit vehicle Details" centered visible={visibleModal} footer={null} onCancel={() => setVisibleModal(false)} width={1000}>
        <Form onFinish={updateVehicleFunction} layout="vertical" initialValues={defaultValue} form={form3}>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item name="colour" label="Colour:">
                <Input placeholder="Enter Colour" disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                // initialValue={time}
                name="name"
                label="Registration Expiry Date:"
                initialValue={moment(time)}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Registration Expiry Date",
                  },
                ]}
              >
                <DatePicker
                  // defaultValue={moment(time, dateFormat)}
                  // format={customFormat}
                  format={dateFormat}
                  style={{ width: "100%" }}
                  onChange={onChange}
                  onOk={onOk}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item name="vin_no" label="Vin Number:">
                <Input placeholder="Enter Vin Number" disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item name="engine_no" label="Engine Number:">
                <Input placeholder="Enter Engine Number" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item name="vehical_name" label="Vehicle Name:">
                <Input placeholder="Enter Vehicle Name" disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item name="rego" label="Registration Number:">
                <Input placeholder="Enter Registration Number" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center ">
            <Col md={10} xs={16}>
              <Form.Item
                name="state_id"
                label="State:"
                rules={[
                  {
                    required: true,
                    message: "Please Select State",
                  },
                ]}
              >
                <Select>{getStateListDetails()}</Select>
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="user_id"
                label="Driver:"
                rules={[
                  {
                    required: true,
                    message: "Please Select User",
                  },
                ]}
              >
                <Select showSearch optionFilterProp="children">
                  {getUserListDetails()}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button className="style-btn" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(VehicaleDetail);
