import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Table } from "antd";
import * as FileSaver from "file-saver";
import { Fragment, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { getFacility } from "../../../redux/actions/facility.action";
import { getStaffListFacility } from "../../../redux/actions/getStaffList.action";
import { getStaffListOrganization } from "../../../redux/actions/getStaffListOrg.action";
import { getGroup } from "../../../redux/actions/group.action";
import { getApiArrayDetails, handleTableSort, isValidArray } from "../../../utils/MyMUtils";
import generatePDFStaffList from "./generatePdfStaffList";

const { Option } = Select;

const StaffList = ({ csvData }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("Staff List");

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const groupList = useSelector((state) => getApiArrayDetails(state.GroupList.data));
  const facilitiesList = useSelector((state) => getApiArrayDetails(state.FacilitiesList.data));
  const getStaffList = useSelector((state) => getApiArrayDetails(state.getStaffList.data));
  const getStaffListOrg = useSelector((state) => getApiArrayDetails(state.getStaffListOrg.data));

  useEffect(() => {
    dispatch(getGroup());
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [facilitiesList]);

  const exportToCSV = (csvData, fileName) => {
    if (csvData) {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast.error("No Data in Table!");
    }
  };

  const excelData = () => {
    if (isValidArray(getStaffList)) {
      return getStaffList.map((item) => {
        return {
          Name: item.Name,
          "Organization Name": item.org_name,
          "Facility Name": item.facility_name,
        };
      });
    } else if (isValidArray(getStaffListOrg)) {
      return getStaffListOrg?.map((item) => {
        return {
          Name: item.Name,
          "Organization Name": item.org_name,
          "Facility Name": item.facility_name,
        };
      });
    }
  };

  const getGroupList = () => {
    if (isValidArray(groupList)) {
      return groupList.map((group) => {
        return (
          <Fragment key={group?.org_id}>
            <Option value={group?.org_id}>{group?.org_name}</Option>
          </Fragment>
        );
      });
    }
  };

  const getFacilityList = () => {
    if (isValidArray(facilitiesList)) {
      return facilitiesList.map((facility) => {
        return (
          <Fragment key={facility?.facility_id}>
            <Option value={facility?.facility_id}>{facility?.facility_name}</Option>
          </Fragment>
        );
      });
    }
  };

  const handleOnChange = (event) => {
    setLoading(true);
    dispatch(getFacility(event));
    dispatch(getStaffListOrganization(event)).then((record) => {
      if (isValidArray(record.payload.result)) {
        toast.success(record.payload.message);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(record.payload.message);
      }
    });
  };

  const handleFacilityID = (event) => {
    setLoading(true);
    dispatch(getStaffListFacility(event)).then((record) => {
      if (isValidArray(record.payload.result)) {
        toast.success(record.payload.message);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(record.payload.message);
      }
    });
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "Name",
      key: "Name",
      width: "20%",
      sorter: (a, b) => handleTableSort(a, b, "Name"),
    },
    {
      title: "Organization Name",
      dataIndex: "org_name",
      key: "org_name",
      width: "20%",
      sorter: (a, b) => handleTableSort(a, b, "org_name"),
    },
    {
      title: "Facility Name",
      dataIndex: "facility_name",
      key: "facility_name",
      width: "15%",
      sorter: (a, b) => handleTableSort(a, b, "facility_name"),
    },
  ];

  return (
    <>
      <Form layout="horizontal" form={form}>
        <Row gutter={24}>
          <Col span={8}></Col>
          <Col span={5}>
            <Form.Item>
              <div>
                <Select onChange={handleOnChange} placeholder="Select Group" allowClear>
                  {getGroupList()}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item name="demo2">
              <div>
                <Select mode="multiple" onChange={handleFacilityID} placeholder="Select Facility">
                  {getFacilityList()}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button className="ml-2" style={{ float: "right" }} onClick={() => generatePDFStaffList(isValidArray(getStaffList) ? getStaffList : getStaffListOrg, columns)} icon={<DownloadOutlined />}>
              PDF
            </Button>
            <Button style={{ float: "right" }} onClick={(e) => exportToCSV(excelData(), fileName)} icon={<DownloadOutlined />}>
              Excel
            </Button>
          </Col>
        </Row>
      </Form>
      <Form>
        <Table
          loading={loading}
          className="mt-1"
          // dataSource={isValidArray(getStaffList) ? getStaffList :  isValidArray(getStaffListOrg)? getStaffListOrg : [] }
          dataSource={isValidArray(getStaffList) && isValidArray(getStaffListOrg) ? getStaffList : [] || isValidArray(getStaffListOrg) ? getStaffListOrg : []}
          columns={columns}
          rowKey="id"
        />
      </Form>
    </>
  );
};

export default memo(StaffList);
