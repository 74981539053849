import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Modal, Popconfirm, Radio, Row, Select, Table, Tooltip } from "antd";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteManageHoliday } from "redux/actions/deleteManageHoliday.action";
import { getFacilityInfo } from "redux/actions/getAllFacilityList.action";
import { getCountryInfo } from "redux/actions/getAllNationList.action";
import { getStateListForHoliday } from "redux/actions/getAllStateForHoliday.action";
import { getFacilityForHoliday } from "redux/actions/getFacilityForHoliday.action";
import { getHolidayListForManageHoliday } from "redux/actions/getHolidayListForManageHoliday.action";
import { getManageHoliday } from "redux/actions/getManageHoliday.action";
import { postManageHoliday } from "redux/actions/postManageHoliday.action";
import { getApiArrayDetails, handleTableSort, isValidArray } from "../../../utils/MyMUtils";

const ManageHoliday = () => {
  const { Option } = Select;
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [lodingCountry, setLoadingCountry] = useState(false);
  const [lodingState, setLoadingState] = useState(false);
  const [lodingFacility, setLoadingFacility] = useState(false);

  const [visible, setVisible] = useState(false);
  const [isNation, setIsNation] = useState(1);
  const [isState, setIsState] = useState(0);
  const [isFacility, setIsFacility] = useState(0);
  const [setCountry, setSetCountry] = useState([]);
  const [setState, setSetState] = useState([]);
  const [setFacility, setSetFacility] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment());

  const [getCountry, setGetCountry] = useState([]);
  const [getState, setGetState] = useState([]);
  const [getFacility, setGetFacility] = useState([]);
  console.log("🚀 ~ ManageHoliday ~ getFacility:", getFacility);
  const [value, setValue] = useState("nation");
  const [holidayDate, setHolidayDate] = useState("");

  const holidayData = useSelector((state) => getApiArrayDetails(state.holidayListForManageHoliday.data));
  const nationData = useSelector((state) => getApiArrayDetails(state.getNationList.data));
  const stateData = useSelector((state) => getApiArrayDetails(state.holidayStateList.data));
  const facilityData = useSelector((state) => getApiArrayDetails(state.getFacilityList.data));
  const getManageHolidayData = useSelector((state) => getApiArrayDetails(state.getManageHoliday.data));
  const getFacilityForHolidayData = useSelector((state) => getApiArrayDetails(state.getFacilityForHolidayList.data));

  const getHolidayListDetails = () => {
    if (isValidArray(holidayData)) {
      return holidayData.map((holidayData) => (
        <Option name="state_id" disabled={holidayData.for_country === 1 ? true : null} key={holidayData.id} value={holidayData.id}>
          {holidayData.name}
        </Option>
      ));
    }
  };

  const getCountryListDetails = () => {
    if (isValidArray(nationData)) {
      return nationData.map((nationData) => (
        <Option name="country_id" key={nationData.country_id} value={nationData.country_id}>
          {nationData.country_name}
        </Option>
      ));
    }
  };

  const getStateListDetails = () => {
    if (isValidArray(stateData)) {
      return stateData.map((stateData) => (
        <Option name="state_id" key={stateData.state_id} value={stateData.state_id}>
          {stateData.state_name}
        </Option>
      ));
    }
  };
  const getFacilityListDetails = () => {
    if (isValidArray(facilityData)) {
      return facilityData.map((facilityData) => (
        <Option name="facility_id" key={facilityData.facility_id} value={facilityData.facility_id}>
          {facilityData.facility_name}
        </Option>
      ));
    }
  };
  const getFacilityListForHolidayDetails = () => {
    if (isValidArray(getFacilityForHolidayData)) {
      return getFacilityForHolidayData.map((facilityData) => (
        <Option name="facility_id" key={facilityData.facility_id} value={facilityData.facility_id}>
          {facilityData.facility_name}
        </Option>
      ));
    }
  };

  useEffect(() => {
    dispatch(getHolidayListForManageHoliday(selectedYear.year()));
    dispatch(getStateListForHoliday());
    dispatch(getFacilityInfo());
    dispatch(getCountryInfo());
    dispatch(getFacilityForHoliday());
  }, []);

  useEffect(() => {
    form.resetFields();
    setValue("nation");
  }, [visible]);

  const deleteFunction = (elm) => {
    dispatch(deleteManageHoliday(elm.id)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        const getBodyData = {
          country_id: getCountry,
          state_id: getState,
          facility_id: getFacility,
        };
        dispatch(getManageHoliday(getBodyData));
      } else {
        toast.error(record.payload.message);
        const getBodyData = {
          country_id: getCountry,
          state_id: getState,
          facility_id: getFacility,
        };
        dispatch(getManageHoliday(getBodyData));
      }
    });
  };
  const cancel = () => {};
  const column = [
    {
      title: "Holiday",
      dataIndex: "name",
      key: "name",
      width: "30%",
    },
    {
      title: "Date",
      dataIndex: "holiday_date",
      key: "holiday_date",
      width: "30%",
      sorter: (a, b) => moment(a.holiday_date).unix() - moment(b.holiday_date).unix(),
      render: (_, elm) => {
        return moment(elm.holiday_date).format("DD-MM-YYYY");
      },
    },
  ];
  const column1 = [
    {
      title: "Holiday",
      dataIndex: "name",
      key: "name",
      width: "30%",
    },
    {
      title: "State",
      dataIndex: "state_name",
      key: "state_name",
      width: "30%",
    },

    {
      title: "Date",
      dataIndex: "holiday_date",
      key: "holiday_date",
      width: "20%",
      sorter: (a, b) => moment(a.holiday_date).unix() - moment(b.holiday_date).unix(),
      render: (_, elm) => {
        return moment(elm.holiday_date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "20%",
      render: (_, elm) => (
        <div>
          <span className="mr-3">
            <Popconfirm title="Are you sure to delete this data?" onConfirm={() => deleteFunction(elm)} onCancel={() => cancel} okText="Yes" cancelText="No">
              <Tooltip title="Delete" color="rgb(20, 72, 129)">
                <Button>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </span>
        </div>
      ),
    },
  ];
  const column2 = [
    {
      title: "Holiday",
      dataIndex: "name",
      key: "name",
      width: "20%",
      sorter: (a, b) => handleTableSort(a, b, "name"),
    },
    {
      title: "State",
      dataIndex: "state_name",
      key: "state_name",
      width: "20%",
      sorter: (a, b) => handleTableSort(a, b, "state_name"),
    },
    {
      title: "Facility",
      dataIndex: "facility_name",
      key: "facility_name",
      width: "30%",
      sorter: (a, b) => handleTableSort(a, b, "facility_name"),
    },

    {
      title: "Date",
      dataIndex: "holiday_date",
      key: "holiday_date",
      width: "10%",
      sorter: (a, b) => moment(a.holiday_date).unix() - moment(b.holiday_date).unix(),
      render: (_, elm) => {
        return moment(elm.holiday_date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "20%",
      render: (_, elm) => (
        <div>
          <span className="mr-3">
            <Popconfirm title="Are you sure to delete this data?" onConfirm={() => deleteFunction(elm)} onCancel={() => cancel} okText="Yes" cancelText="No">
              <Tooltip title="Delete" color="rgb(20, 72, 129)">
                <Button>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </span>
        </div>
      ),
    },
  ];

  const onChange = (e) => {
    setValue(e.target.value);
    if (e.target.value === "nation") {
      setIsFacility(0);
      setIsState(0);
      setIsNation(1);
    } else if (e.target.value === "state") {
      setIsFacility(0);
      setIsNation(0);
      setIsState(1);
    } else if (e.target.value === "facility") {
      setIsState(0);
      setIsNation(0);
      setIsFacility(1);
    } else {
    }
  };

  const onDateFormateChange = (date, dateString) => {
    const dateValue = moment(date).format("YYYY-MM-DD");
    setHolidayDate(dateValue);
  };

  const onChangeNation = (value) => {
    setSetCountry(value);
    setSetState([]);
    setSetFacility([]);
  };
  const onChangeState = (value) => {
    setSetState(value);
    setSetCountry([]);
    setSetFacility([]);
  };
  const onChangeFacility = (value) => {
    setSetFacility(value);
    setSetCountry([]);
    setSetState([]);
  };

  const onChangeGetCountry = (value) => {
    setLoadingCountry(true);
    setGetCountry(value);
    setGetState([]);
    setGetFacility([]);
    const getBodyData = {
      country_id: value,
      state_id: [],
      facility_id: [],
      year: selectedYear.year(),
    };
    if (value.length === 0) return;
    dispatch(getManageHoliday(getBodyData)).then((record) => {
      if (record.payload.success) {
        setLoadingCountry(false);
      } else {
        setLoadingCountry(false);
      }
    });
  };
  const onChangeGetState = (value) => {
    setLoadingState(true);
    setGetState(value);
    setGetCountry([]);
    setGetFacility([]);
    const getBodyData = {
      country_id: [],
      state_id: value,
      facility_id: [],
      year: selectedYear.year(),
    };
    if (value.length === 0) return;
    dispatch(getManageHoliday(getBodyData)).then((record) => {
      if (record.payload.success) {
        setLoadingState(false);
      } else {
        setLoadingState(false);
      }
    });
  };
  const onChangeGetFacility = (value) => {
    setLoadingFacility(true);
    setGetFacility(value);
    setGetCountry([]);
    setGetState([]);
    const getBodyData = {
      country_id: [],
      state_id: [],
      facility_id: value,
      year: selectedYear.year(),
    };
    if (value.length === 0) return;
    dispatch(getManageHoliday(getBodyData)).then((record) => {
      if (record.payload.success) {
        setLoadingFacility(false);
      } else {
        setLoadingFacility(false);
      }
    });
  };

  const PostData = (data) => {
    // const PostBodyData = {
    //   holiday_id: data.holiday_id,
    //   holiday_date: holidayDate,
    //   country_id: setCountry,
    //   state_id: setState,
    //   facility_id: setFacility,
    //   for_country: isNation,
    //   for_state: isState,
    //   for_facility: isFacility,
    // };

    const PostBodyData = {
      holiday_id: data.holiday_id,
      holiday_date: holidayDate,
      country_id: data.country_id,
      state_id: data.state_id,
      facility_id: data.facility_id,
      for_country: isNation,
      for_state: isState,
      for_facility: isFacility,
    };
    const getBodyData = {
      country_id: getCountry,
      state_id: getState,
      facility_id: getFacility,
    };
    dispatch(postManageHoliday(PostBodyData)).then((record) => {
      if (record.payload.success) {
        toast.success(record.payload.message);
        setVisible(false);
        dispatch(getManageHoliday(getBodyData));
      } else {
        toast.success(record.payload.message);
        setVisible(false);
        dispatch(getManageHoliday(getBodyData));
      }
      dispatch(getHolidayListForManageHoliday());
      dispatch(getFacilityForHoliday());
    });
  };

  const disabledYear = (current) => {
    const previousYear = moment().subtract(1, "y").year();
    const nextYear = moment().add(1, "y").year();
    // Can not select days before today and today
    // return current && current < moment().endOf('day');
    return current.year() < previousYear || current.year() > nextYear;
  };

  const disabledForSubmitFormYear = (current) => {
    return current.year() !== selectedYear.year();
  };

  const handleDateChange = (moment, callBack) => {
    setGetCountry([]);
    setGetState([]);
    setGetFacility([]);
    callBack();
    dispatch(getHolidayListForManageHoliday(moment.year()));
  };

  return (
    <>
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item name="date_picker">
            <div>
              <DatePicker
                className="w-100"
                disabledDate={disabledYear}
                onChange={(moment) => handleDateChange(moment, () => setSelectedYear(moment))}
                picker="year"
                defaultValue={selectedYear}
                allowClear={false}
              />
            </div>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="select_country">
            <div>
              <Select value={getCountry} onChange={onChangeGetCountry} mode="multiple" placeholder="Select Country">
                {getCountryListDetails()}
              </Select>
            </div>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item>
            <div>
              <Select value={getState} onChange={onChangeGetState} mode="multiple" placeholder="Select State">
                {getStateListDetails()}
              </Select>
            </div>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item>
            <div>
              <Select value={getFacility} onChange={onChangeGetFacility} mode="multiple" placeholder="Select Facility">
                {getFacilityListDetails()}
              </Select>
            </div>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Button className="style-btn" onClick={() => setVisible(true)} style={{ float: "right", marginBottom: "20px" }} icon={<PlusCircleOutlined />} shape="round" size="default">
            Manage Holiday
          </Button>
        </Col>
      </Row>
      {!getCountry.length > 0 && !getState.length > 0 && !getFacility.length > 0 ? <Table columns={column} /> : ""}
      {getCountry.length > 0 ? <Table loading={lodingCountry} columns={column} dataSource={getManageHolidayData} /> : ""}
      {getState.length > 0 ? <Table loading={lodingState} columns={column1} dataSource={getManageHolidayData} /> : ""}
      {getFacility.length > 0 ? <Table loading={lodingFacility} columns={column2} dataSource={getManageHolidayData} /> : ""}

      <Modal title="Manage Holiday" centered visible={visible} footer={null} onCancel={() => setVisible(false)} width={500}>
        <Form onFinish={PostData} layout="vertical" form={form}>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="holiday_id"
                label="Holiday"
                rules={[
                  {
                    required: true,
                    message: "Please Select Holiday.",
                  },
                ]}
              >
                <Select>{getHolidayListDetails()}</Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item name="valid_from" label="Holiday Date" rules={[{ required: true, message: "Please Select Date." }]}>
                <DatePicker disabledDate={disabledForSubmitFormYear} format={"DD-MM-YYYY"} onChange={onDateFormateChange} style={{ width: "450px" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item className="btn-weekpicker" label="Holiday For">
                <Radio.Group onChange={onChange} value={value}>
                  <Radio value={"nation"}>Country</Radio>
                  <Radio value={"state"}>State</Radio>
                  <Radio value={"facility"}>Facility</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              {value === "nation" ? (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please Select Country.",
                    },
                  ]}
                  name="country_id"
                  label="Select Country"
                >
                  <Select value={setCountry} onChange={onChangeNation} mode="multiple">
                    {getCountryListDetails()}
                  </Select>
                </Form.Item>
              ) : (
                ""
              )}
              {value === "state" ? (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please Select State.",
                    },
                  ]}
                  name="state_id"
                  label="Select State"
                >
                  <Select value={setState} onChange={onChangeState} mode="multiple">
                    {getStateListDetails()}
                  </Select>
                </Form.Item>
              ) : (
                ""
              )}
              {value === "facility" ? (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please Select Facility.",
                    },
                  ]}
                  name="facility_id"
                  label="Select Facility"
                >
                  <Select value={setFacility} onChange={onChangeFacility} mode="multiple">
                    {getFacilityListDetails()}
                  </Select>
                </Form.Item>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default memo(ManageHoliday);
