import { DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from "antd";
import * as FileSaver from "file-saver";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteGroup } from "redux/actions/deleteGroup.action";
import { deleteUser } from "redux/actions/deleteUser.action";
import { getStateInfo } from "redux/actions/getAllStateList.action";
import { getExistEmailFacilityManagement } from "redux/actions/getEmailExist.action";
import { getExistEmailAtEditFacilityManagement } from "redux/actions/getEmailExistAtEdit.action";
import { getGroupInfo } from "redux/actions/getGroup.action";
import { postNewGroup } from "redux/actions/postGroup.action";
import { updateGroup } from "redux/actions/updateGroup.action";
import * as XLSX from "xlsx";
import { getApiArrayDetails, handleTableSort, isValidArray } from "../../../utils/MyMUtils";
import generatePDFGroup from "./generatePdfGroup";
const { Option } = Select;

function GroupContact({ csvData }) {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  let searchInput = useRef(null);

  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("Group Details");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const organizationList = useSelector((state) => getApiArrayDetails(state.getGroupInfo.data));
  const stateList = useSelector((state) => getApiArrayDetails(state.getStateList.data));

  useEffect(() => {
    dispatch(getGroupInfo());
  }, []);

  useEffect(() => {
    dispatch(getStateInfo());
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [isModalVisible]);

  useEffect(() => {
    form2.resetFields();
  }, [visibleModal]);

  useEffect(() => {
    form3.resetFields();
  }, [visible]);

  const exportToCSV = (csvData, fileName) => {
    if (csvData) {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast.error("No Data in Table!");
    }
  };

  const excelData = () => {
    if (isValidArray(organizationList)) {
      return organizationList?.map((item) => {
        return {
          Group: item.group_name,
          "Contact Person": item.contact_name,
          Email: item.email,
          "Contact Person": item.contact_name,
          "Contact Number": item.contact_number,
        };
      });
    }
  };

  const postNewGroupFunction = (data) => {
    dispatch(postNewGroup(data)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getGroupInfo());
      } else {
        toast.error(record.payload.message);
        dispatch(getGroupInfo());
      }
    });
    setVisible(false);
  };

  const ViewGroupFunction = (elm) => {
    setDefaultValue(elm);
    setIsModalVisible(true);
  };

  const EditGroupFunction = (elm) => {
    setDefaultValue(elm);
    setVisibleModal(true);
  };

  const deleteGroupFunction = (elm) => {
    dispatch(deleteGroup(elm.org_id)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getGroupInfo());
      } else {
        toast.error(record.payload.message);
        dispatch(getGroupInfo());
      }
    });
  };

  const getStateListDetails = () => {
    if (isValidArray(stateList)) {
      return stateList.map((state) => {
        return (
          <Option key={state.state_id} value={state.state_id}>
            {state.state_name}
          </Option>
        );
      });
    }
  };

  const editNewGroupFunction = (elm) => {
    let array = [];

    elm.user_details?.map((item) => {
      array.push(item);
    });

    const data = {
      ...elm,
      group_login: elm?.group_login ? [...array, ...elm?.group_login] : [...array],
      org_id: defaultValue.org_id,
      state_id: elm.state_id,
    };
    //delete data.state_id;

    dispatch(updateGroup(data)).then((record) => {
      if (record.payload.success == true) {
        setVisibleModal(false);
        toast.success(record.payload.message);
        dispatch(getGroupInfo());
      } else {
        setVisibleModal(false);
        toast.error(record.payload.message);
        dispatch(getGroupInfo());
      }
    });
  };

  const cancel = () => {};

  const checkEmail = (e) => {
    let email = e?.target?.value;
    dispatch(getExistEmailFacilityManagement(email)).then((record) => {
      if (record.payload.success == true) {
        toast.error(record.payload.message);
      }
    });
  };

  const checkEmailEdit = (e) => {
    let email = e?.target?.value;
    dispatch(getExistEmailAtEditFacilityManagement(email)).then((record) => {
      if (record.payload.success == true) {
        toast.error(record.payload.message);
      }
    });
  };

  const deleteUserHandle = (e) => {
    let user_id = defaultValue?.user_details[e]?.user_id;
    dispatch(deleteUser(user_id)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
      }
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: "#1a3353" ? "#1a3353" : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Group",
      dataIndex: "group_name",
      key: "group_name",
      width: "30%",
      ...getColumnSearchProps("group_name"),
      sorter: (a, b) => handleTableSort(a, b, "group_name"),
    },
    {
      title: "Contact Person",
      dataIndex: "contact_name",
      key: "contact_name",
      width: "30%",
      sorter: (a, b) => handleTableSort(a, b, "contact_name"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "40%",
      render: (_, elm, row) => (
        <div>
          <span className="mr-3">
            <Tooltip title="View" color="rgb(20, 72, 129)">
              <Button onClick={() => ViewGroupFunction(elm)}>
                <EyeOutlined />
              </Button>
            </Tooltip>
          </span>
          <span className="mr-3">
            <Tooltip title="Edit" color="rgb(20, 72, 129)">
              <Button onClick={() => EditGroupFunction(elm)}>
                <EditOutlined />
              </Button>
            </Tooltip>
          </span>
          <span className="mr-3">
            <Popconfirm title="Are you sure to delete this data?" onConfirm={() => deleteGroupFunction(elm)} onCancel={() => cancel} okText="Yes" cancelText="No">
              <Tooltip title="Delete" color="rgb(20, 72, 129)">
                <Button>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Form>
        <Button className="style-btn ml-2" onClick={() => setVisible(true)} style={{ float: "right", marginBottom: "20px" }} icon={<PlusCircleOutlined />} shape="round" type="primary">
          New Group
        </Button>
        <Button shape="round" className="ml-2" style={{ float: "right" }} onClick={() => generatePDFGroup(organizationList, columns)} icon={<DownloadOutlined />}>
          PDF
        </Button>
        <Button shape="round" style={{ float: "right" }} onClick={(e) => exportToCSV(excelData(), fileName)} icon={<DownloadOutlined />}>
          Excel
        </Button>
        <Table columns={columns} dataSource={organizationList} loading={!isValidArray(organizationList)} />
      </Form>
      <Modal title="New Group" centered visible={visible} footer={null} onCancel={() => setVisible(false)} width={1000}>
        <Form layout="vertical" onFinish={postNewGroupFunction} form={form3} className="facility-steps-content">
          <Row gutter={24} className="justify-content-center m-0">
            <Col md={10} xs={16}>
              <Form.Item
                name="group_name"
                label="Group Name:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Group Name",
                  },
                ]}
              >
                <Input placeholder="Enter Group Name" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="state_id"
                label="Select State:"
                rules={[
                  {
                    required: true,
                    message: "Please Select State",
                  },
                ]}
              >
                <Select placeholder="Select State">{getStateListDetails()}</Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} className="justify-content-center m-0">
            <Col md={10} xs={16}>
              <Form.Item
                name="contact_name"
                label="Contact Person:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Contact Person",
                  },
                ]}
              >
                <Input placeholder="Enter Contact Person" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="contact_number"
                label="Contact Number:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Contact Number",
                  },
                ]}
              >
                <Input placeholder="Enter Contact Number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center m-0">
            <Col md={10} xs={16}>
              <Form.Item
                name="email"
                label="Email:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Email",
                  },
                  {
                    type: "email",
                    message: "Please Enter valid Email address",
                  },
                ]}
              >
                <Input placeholder="Enter Contact Email" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}></Col>
          </Row>

          <Divider className="m-0">Access to Facility Portal</Divider>
          <Row gutter={[16, 16]} className="d-flex justify-content-center m-0">
            <Col span={20}>
              <Form.List name="group_login">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                        <Form.Item {...restField} name={[name, "first_name"]} fieldKey={[fieldKey, "first_name"]} rules={[{ required: true, message: "Please Enter First Name" }]}>
                          <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item {...restField} name={[name, "last_name"]} fieldKey={[fieldKey, "last_name"]} rules={[{ required: true, message: "Please Enter Last Name" }]}>
                          <Input placeholder="Last Name" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "email"]}
                          fieldKey={[fieldKey, "email"]}
                          rules={[
                            { required: true, message: "Please Enter Email" },
                            {
                              type: "email",
                              message: "Please Enter valid Email address",
                            },
                          ]}
                          onBlur={checkEmail}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item {...restField} name={[name, "password"]} fieldKey={[fieldKey, "password"]} rules={[{ required: true, message: "Please Enter Password" }]}>
                          <Input placeholder="Password" />
                        </Form.Item>
                        <DeleteOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}

                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusCircleOutlined />}>
                        Add User
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>

          <Row gutter={24} className="justify-content-center m-0">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button className="style-btn" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal title="View Group" centered visible={isModalVisible} footer={null} onCancel={() => setIsModalVisible(false)} width={900}>
        <Form layout="vertical" initialValues={defaultValue} form={form} className="facility-steps-content">
          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={10} xs={16}>
              <Form.Item name="group_name" label="Group Name:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item name="state_name" label="State Name:">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={10} xs={16}>
              <Form.Item name="contact_name" label="Contact Person:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item name="contact_number" label="Contact Number:">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={10} xs={16}>
              <Form.Item name="email" label="Email:">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}></Col>
          </Row>
          <Divider>Access to Facility Portal</Divider>

          <Form.List name="user_details">
            {(fields, { add, remove }) => (
              <>
                <Row className="justify-content-center">
                  <Col md={20}>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                        <Form.Item {...restField} name={[name, "first_name"]} label={"First Name"} fieldKey={[fieldKey, "first_name"]} rules={[{ required: true, message: "Please Enter First Name" }]}>
                          <Input placeholder="First Name" disabled />
                        </Form.Item>
                        <Form.Item {...restField} name={[name, "last_name"]} label={"Last Name"} fieldKey={[fieldKey, "last_name"]} rules={[{ required: true, message: "Please Enter Last Name" }]}>
                          <Input placeholder="Last Name" disabled />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "email"]}
                          label={"Email"}
                          fieldKey={[fieldKey, "email"]}
                          rules={[
                            { required: true, message: "Please Enter Email" },
                            {
                              type: "email",
                              message: "Please Enter valid Email address",
                            },
                          ]}
                        >
                          <Input placeholder="Email" disabled />
                        </Form.Item>
                      </Space>
                    ))}
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>

      <Modal title="Edit Group" centered visible={visibleModal} footer={null} onCancel={() => setVisibleModal(false)} width={900}>
        <Form layout="vertical" form={form2} initialValues={defaultValue} onFinish={editNewGroupFunction} className="facility-steps-content">
          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={10} xs={16}>
              <Form.Item
                name="group_name"
                label="Group Name:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Group Name",
                  },
                ]}
              >
                <Input placeholder="Enter Group Name" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="state_id"
                label="Select State:"
                rules={[
                  {
                    required: true,
                    message: "Please Select State",
                  },
                ]}
              >
                <Select placeholder="Select State">{getStateListDetails()}</Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={10} xs={16}>
              <Form.Item
                name="contact_name"
                label="Contact Person:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Contact Person",
                  },
                ]}
              >
                <Input placeholder="Enter Contact Person" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}>
              <Form.Item
                name="contact_number"
                label="Contact Number:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Contact Number",
                  },
                ]}
              >
                <Input placeholder="Enter Contact Number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="justify-content-center m-0">
            <Col md={10} xs={16}>
              <Form.Item
                name="email"
                label="Email:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Email",
                  },
                  {
                    type: "email",
                    message: "Please Enter valid Email address",
                  },
                ]}
              >
                <Input placeholder="Enter Contact Email" />
              </Form.Item>
            </Col>
            <Col md={10} xs={16}></Col>
          </Row>
          <Divider>Access to Facility Portal</Divider>
          <Row gutter={[16, 16]} className="d-flex justify-content-center m-0">
            <Col span={24}>
              <Form.List name="user_details">
                {(fields, { add, remove }) => (
                  <>
                    <Row className="justify-content-center">
                      <Col md={20}>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Space key={key} align="baseline">
                            <Form.Item {...restField} name={[name, "first_name"]} fieldKey={[fieldKey, "first_name"]} rules={[{ required: true, message: "Please Enter First Name" }]}>
                              <Input placeholder="First Name" />
                            </Form.Item>
                            <Form.Item {...restField} name={[name, "last_name"]} fieldKey={[fieldKey, "last_name"]} rules={[{ required: true, message: "Please Enter Last Name" }]}>
                              <Input placeholder="Last Name" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "email"]}
                              fieldKey={[fieldKey, "email"]}
                              rules={[
                                { required: true, message: "Please Enter Email" },
                                {
                                  type: "email",
                                  message: "Please Enter valid Email address",
                                },
                              ]}
                              onBlur={checkEmailEdit}
                            >
                              <Input placeholder="Email" />
                            </Form.Item>
                            <Button onClick={() => remove(name)} icon={<DeleteOutlined onClick={() => deleteUserHandle(name)} />}></Button>
                          </Space>
                        ))}
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="d-flex justify-content-center mt-1 m-0">
            <Col span={24}>
              <Form.List name="group_login">
                {(fields, { add, remove }) => (
                  <>
                    <Row className="justify-content-center">
                      <Col md={20}>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                            <Form.Item {...restField} name={[name, "first_name"]} fieldKey={[fieldKey, "first_name"]} rules={[{ required: true, message: "Missing first name" }]}>
                              <Input placeholder="First Name" />
                            </Form.Item>
                            <Form.Item {...restField} name={[name, "last_name"]} fieldKey={[fieldKey, "last_name"]} rules={[{ required: true, message: "Missing last name" }]}>
                              <Input placeholder="Last Name" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "email"]}
                              fieldKey={[fieldKey, "email"]}
                              rules={[
                                { required: true, message: "Missing last name" },
                                {
                                  type: "email",
                                  message: "Please Enter valid Email address",
                                },
                              ]}
                              onBlur={checkEmail}
                            >
                              <Input placeholder="Email" />
                            </Form.Item>
                            <Form.Item {...restField} name={[name, "password"]} fieldKey={[fieldKey, "password"]} rules={[{ required: true, message: "Please Enter Password" }]}>
                              <Input placeholder="Password" />
                            </Form.Item>
                            <Button onClick={() => remove(name)} icon={<DeleteOutlined />}></Button>
                          </Space>
                        ))}
                      </Col>
                    </Row>
                    <Row gutter={20} className="m-0">
                      <Col md={20} xs={16} offset={2}>
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusCircleOutlined />}>
                            Add User
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center m-0">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button className="style-btn" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default GroupContact;
