import { GET_ALL_LAUNDRY_SUCCESS, GET_ALL_LAUNDRY_FAILURE } from "../constants/MyM";
import { getApi } from "../../utils/apiCall";

const getAllLaundrySuccess = (data) => {
    return {
        type: GET_ALL_LAUNDRY_SUCCESS,
        payload: data,
    };
};

const getAllLaundryFailure = (data) => {
    return {
        type: GET_ALL_LAUNDRY_FAILURE,
        payload: data,
    };
};

export const getAllLaundry = (data, id, date) => {
    return async (dispatch, getState) => {
        const { error, result } = await getApi(`/laundry_survey_details?fac_id=${id}&survey_date=${date}`, {
            ...data,
        });
        if (!error) {
            return dispatch(getAllLaundrySuccess(result));
        }
        return dispatch(getAllLaundryFailure(result));
    };
};