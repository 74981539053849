import { DownloadOutlined, EyeOutlined, MailOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, message, Modal, Popover, Radio, Row, Select, Spin, Table, Tooltip, Upload } from "antd";
import * as FileSaver from "file-saver";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUserList } from "redux/actions/getUserList.action";
import { getPoliceCheckData } from "redux/actions/policeCheckCertificate.action";
import { uploadCertificate } from "redux/actions/uploadCertificate.action";
import { uploadPoliceCheck } from "redux/actions/uploadPoliceCheck.action";
import * as XLSX from "xlsx";
import { getApiArrayDetails, getPreSignedUrl, handleTableSort, isValidArray } from "../../../utils/MyMUtils";
import Email from "./../mail";
import generatePDFPoliceCheck from "./generatePdfPoliceCheck";
import "./index.css";

const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

function PoliceCheckCertificate() {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const [userId, setUserId] = useState("");
  const [refNumber, setRefNumber] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [uploadInfo, setUploadInfo] = useState({});
  const [policeCheckData, setPoliceCheckData] = useState([]);
  const [policeInfo, setPoliceInfo] = useState([]);
  const [visible, setVisible] = useState(false);
  const [staffIdsList, setStaffIdsList] = useState("");
  const [staffIdForUpload, setStaffIdForUpload] = useState("");
  const [visibleMail, setVisibleMail] = useState(false);
  const [showOffenceType, setShowOffenceType] = useState(false);
  const [offences, setOffence] = useState(0);
  const [certificateSited, setCertificateSited] = useState(1);
  const [fileName, setFileName] = useState("Police Check Certificates");

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const policeCheckList = useSelector((state) => getApiArrayDetails(state.policeStatement.data));

  const fileLoadingState = useSelector((state) => state.fileLoadingReducer.fileLoadingState);

  const getUserListData = useSelector((state) => getApiArrayDetails(state.getUserList.data));

  useEffect(() => {
    dispatch(getUserList());
    return () => {};
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  useEffect(() => {
    if (policeCheckList && policeCheckList.length > 2) setPoliceCheckData(policeCheckList);
    return () => {};
  }, [policeCheckList]);

  const getStaffIds = () => {
    if (isValidArray(getUserListData)) {
      let addIds = "";
      getUserListData.map((staff) => {
        addIds += staff.user_id + ",";
      });
      addIds = addIds.substring(0, addIds.length - 1);
      return addIds;
    }
    return null;
  };

  let StaffIds = getStaffIds();

  useEffect(() => {
    setStaffIdsList(StaffIds);
    dispatch(getPoliceCheckData(StaffIds));
  }, [StaffIds]);

  const exportToCSV = (csvData, fileName) => {
    if (csvData) {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast.error("No Data in Table!");
    }
  };

  const excelData = () => {
    if (isValidArray(policeCheckList)) {
      return policeCheckList?.map((item) => {
        return {
          "First Name": item.first_name,
          "Last Name": item.last_name,
          "Police Check Reference": item.ref_number,
          "Certificate Sited": item.certificate_sited == 0 ? "No" : item.certificate_sited == 1 ? "Yes" : "",
          "Are there Offences": item.are_there_offences == 0 ? "No" : item.are_there_offences == 1 ? "Yes" : "",
          "Offence Type": item.offence_type,
          "Certification Date": moment(item.issue_date).format("DD/MM/YYYY"),
          "Certificate Expiry": moment(item.expiry_date).format("DD/MM/YYYY"),
          "Days until Expiry": item.days_until_expiry == 1 ? "" : item.days_until_expiry,
        };
      });
    }
  };

  const getPoliceCheckList = () => {
    if (isValidArray(getUserListData)) {
      return getUserListData.map((staff) => {
        return (
          <Fragment key={staff?.user_id}>
            <Option value={staff?.user_id}>
              {staff?.first_name} {staff?.last_name}
            </Option>
          </Fragment>
        );
      });
    }
    return null;
  };

  const setUploadModel = (elm) => {
    setVisible(true);
    setUserId(elm?.user_id);
  };

  const onChangeCertificate = (e) => {
    setCertificateSited(e.target.value);
  };

  const onChangeOffences = (e) => {
    setShowOffenceType(e.target.value);
    setOffence(e.target.value);
  };

  const postNewUploadFunction = (data) => {
    console.log(data, "data");
    dispatch(uploadCertificate(uploadInfo)).then((record) => {
      const uploadFinal = {
        user_id: userId,
        ref_number: data?.refNumber,
        issue_date: data?.issueDate,
        pdf: record.payload.result.result,
        certificate_sited: certificateSited,
        are_there_offences: offences,
        offence_type: data?.offence_type,
      };
      console.log(uploadFinal, "upload");
      dispatch(uploadPoliceCheck(uploadFinal)).then((record) => {
        if (record.payload.result.success === true) {
          if (staffIdForUpload) {
            dispatch(getPoliceCheckData(staffIdForUpload || userId));
          } else {
            dispatch(getPoliceCheckData(StaffIds));
          }
          toast.success(record.payload.result.message);
        } else {
          toast.error(record.payload.result.message);
        }
      });
    });
    setVisible(false);
  };

  const uploadSettings = {
    accept: ".pdf",
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        message.error(`${file.name} is not a pdf file`);
      }
      return false;
    },
    onChange: (info) => {
      let selectedFile = info.fileList;
      let file = null;
      let fileName;
      if (selectedFile.length > 0) {
        let fileToLoad = selectedFile[selectedFile.length - 1].originFileObj;
        fileName = fileToLoad.name;
        let reader = new FileReader();
        reader.onload = function (fileEvent) {
          file = fileEvent.target.result;

          let name = fileName.split(".");
          const uploadInfo = {
            certificate_type: "police_check",
            filename: name[0],
            file: file,
          };
          setUploadInfo(uploadInfo);
        };
        reader.readAsDataURL(fileToLoad);
      }
    },
  };

  const handleOnChange = (event) => {
    if (event[event.length - 1] == "0") {
      setStaffIdForUpload(staffIdsList);
      dispatch(getPoliceCheckData(staffIdsList));
    }
    if (!isValidArray(event)) {
      setStaffIdForUpload(staffIdsList);
      dispatch(getPoliceCheckData(staffIdsList));
    } else {
      setStaffIdForUpload(event);
      dispatch(getPoliceCheckData(event));
    }
  };

  const closePopover = () => {
    setVisibleMail(false);
  };

  const setMail = (elm) => {
    setVisibleMail(elm.user_id);
    if (visibleMail === elm.user_id) {
      setVisibleMail(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "25%",
      render: (text, record) => {
        return `${text} ${" "}  ${record?.last_name}`;
      },
      sorter: (a, b) => a.first_name.toUpperCase().charCodeAt(0) - b.first_name.toUpperCase().charCodeAt(0),
      filters: policeCheckList
        ?.map((f) => {
          const name = `${f.first_name} ${f.last_name}`;
          return {
            text: name,
            value: f.user_id,
          };
        })
        .sort((a, b) => a.text.toUpperCase().charCodeAt(0) - b.text.toUpperCase().charCodeAt(0)),
      onFilter: (value, record) => record?.first_name?.includes(value) && record?.last_name?.includes(value),
    },
    {
      title: "Reference Number",
      dataIndex: "ref_number",
      key: "ref_number",
      width: "20%",
    },
    {
      title: "Expiry Date",
      //dataIndex: "expiry_date",
      key: "expiry_date",
      width: "20%",
      render: (text, record) => (text?.ref_number ? `${moment(record?.expiry_date).format(dateFormat)}` : null),
      sorter: (a, b) => handleTableSort(a, b, "expiry_date"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "35%",
      render: (_, elm) => (
        <div>
          <span className="mr-3">
            {policeCheckList
              .filter((item) => elm.user_id == item.user_id)
              .map((item) => {
                return item?.statement_link ? (
                  <Tooltip title="View" color="rgb(20, 72, 129)">
                    <Button onClick={() => getPreSignedUrl(item.statement_link)}>{fileLoadingState !== item.statement_link ? <EyeOutlined /> : <Spin size="small" />}</Button>
                  </Tooltip>
                ) : (
                  <a>
                    <Tooltip title="View" color="rgb(20, 72, 129)">
                      <Button disabled>
                        <EyeOutlined style={{ color: "#00000040" }} />
                      </Button>
                    </Tooltip>
                  </a>
                );
              })}
          </span>
          <span className="mr-3">
            {policeCheckList
              .filter((item) => elm.user_id == item.user_id)
              .map((item) => {
                return item?.statement_link ? (
                  <Popover title="Additional Emails" content={<Email passData={elm} visibleMailprop={closePopover} />} visible={elm.user_id === visibleMail} trigger="click">
                    <Tooltip title="Mail" color="rgb(20, 72, 129)">
                      <Button onClick={() => setMail(elm)}>
                        <MailOutlined />
                      </Button>
                    </Tooltip>
                  </Popover>
                ) : (
                  <Tooltip title="Mail" color="rgb(20, 72, 129)">
                    <Button disabled>
                      <MailOutlined style={{ color: "#00000040" }} />
                    </Button>
                  </Tooltip>
                );
              })}
          </span>

          <span>
            <Tooltip title="Upload" color="rgb(20, 72, 129)">
              <Button onClick={() => setUploadModel(elm)}>
                <UploadOutlined />
              </Button>
            </Tooltip>
          </span>
        </div>
      ),
    },
  ];
  function onChange(value, dateString) {}

  function onOk(value) {}

  return (
    <>
      <Form layout="horizontal">
        {/* <Select
          className="ml-2"
          mode="multiple"
          style={{ float: "right", width: "30%", marginBottom: "20px" }}
          placeholder="Select Staff"
          onChange={handleOnChange}
          showSearch
          optionFilterProp="children"
        >
          <Option value="0">All</Option>
          {getPoliceCheckList()}
        </Select>
        <Button
          shape="round"
          className="ml-2"
          style={{ float: "right" }}
          onClick={() => generatePDFPoliceCheck(policeCheckList, columns)}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
        <Button
          shape="round"
          style={{ float: "right" }}
          onClick={(e) => exportToCSV(excelData(), fileName)}
          icon={<DownloadOutlined />}
        >
          Excel
        </Button> */}
        <Row gutter={24}>
          <Col span={4}></Col>
          <Col span={5}></Col>
          <Col span={6}></Col>
          <Col span={5}>
            <Form.Item>
              <div>
                <Select mode="multiple" onChange={handleOnChange} showSearch optionFilterProp="children" placeholder="Select Staff">
                  <Option value="0">All</Option>
                  {getPoliceCheckList()}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button shape="round" className="ml-2" style={{ float: "right" }} onClick={() => generatePDFPoliceCheck(policeCheckList, columns)} icon={<DownloadOutlined />}>
              PDF
            </Button>
            <Button shape="round" style={{ float: "right" }} onClick={(e) => exportToCSV(excelData(), fileName)} icon={<DownloadOutlined />}>
              Excel
            </Button>
          </Col>
        </Row>
      </Form>
      <Form>
        <Table loading={!isValidArray(policeCheckList)} columns={columns} dataSource={policeCheckList} rowKey="id" />
      </Form>
      <Modal title="Upload" centered visible={visible} footer={null} onCancel={() => setVisible(false)} width={500}>
        <Form onFinish={postNewUploadFunction} layout="vertical" form={form}>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Form.Item
                name="refNumber"
                label="Reference Number:"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Reference Number",
                  },
                ]}
              >
                <Input placeholder="Enter Reference Number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                // name="certificate_sited"
                label="Certificate Sited:"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Certificate Sited",
                //   },
                // ]}
              >
                <Radio.Group name="certificate_sited" className="ml-2" onChange={onChangeCertificate} defaultValue={certificateSited}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={12}>
              <Form.Item label="Offences:">
                <Radio.Group name="offences" className="ml-2" onChange={onChangeOffences} defaultValue={offences} label="Offences:">
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {showOffenceType === 1 ? (
              <Col md={12}>
                <Form.Item name="offence_type" label="Offence Type:">
                  <Input placeholder="Enter Offence Type" />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
          </Row>

          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="issueDate"
                label="Issue Date:"
                initialValue={moment(moment())}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Issue Date",
                  },
                ]}
              >
                <DatePicker
                  // defaultValue={moment(moment(), dateFormat)}
                  format={dateFormat}
                  onChange={onChange}
                  onOk={onOk}
                  // disabledDate={(current) => {
                  //   return moment().add(-1, "days") >= current;
                  // }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="file"
                label="File:"
                rules={[
                  {
                    required: true,
                    message: "Please Upload File",
                  },
                ]}
              >
                <Upload {...uploadSettings} maxCount={1}>
                  {/* <Button onClick={() => policeCheckUpload(elm)}> */}

                  <Button onClick={() => setVisible(true)}>
                    <UploadOutlined />
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default PoliceCheckCertificate;
