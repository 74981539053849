import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Table, Tooltip } from "antd";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteAdhocFacility } from "redux/actions/deleteAdhocFacility.action";
import { getAllAdhocFacility } from "redux/actions/getAllAdhocFacility.action";
import { postAdhocFacility } from "redux/actions/postAdhocFacility.action";
import { putAdhocFacility } from "redux/actions/updateAdhocFacility.action";
import { getApiArrayDetails, handleTableSort } from "../../../utils/MyMUtils";

const AdhocFacilityManagement = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [defaultValue, setDefaultValue] = useState([]);
  const [id, setId] = useState("");

  const AdhocFacilityList = useSelector((state) => getApiArrayDetails(state.getAdhocFacility.data));

  useEffect(() => {
    setLoading(true);
    dispatch(getAllAdhocFacility()).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    form.resetFields();
    form1.resetFields();
  }, [visible, editVisible]);

  const deleteFunction = (elm) => {
    dispatch(deleteAdhocFacility(elm.facility_id)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getAllAdhocFacility());
      } else {
        toast.error(record.payload.message);
        dispatch(getAllAdhocFacility());
      }
    });
  };

  const EditFunction = (e) => {
    setId(e.facility_id);
    setEditVisible(true);
    setDefaultValue({
      ...e,
    });
  };

  const column = [
    {
      title: "Facility",
      dataIndex: "facility_name",
      key: "facility_name",
      width: "30%",
      sorter: (a, b) => handleTableSort(a, b, "facility_name"),
    },
    {
      title: "Job code",
      dataIndex: "job_code",
      key: "job_code",
      width: "30%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "30%",
      render: (_, elm) => (
        <div>
          <span className="mr-3">
            <Tooltip title="Edit" color="rgb(20, 72, 129)">
              <Button onClick={() => EditFunction(elm)}>
                <EditOutlined />
              </Button>
            </Tooltip>
          </span>
          <span className="mr-3">
            <Popconfirm title="Are you sure to delete this data?" onConfirm={() => deleteFunction(elm)} onCancel={() => cancel} okText="Yes" cancelText="No">
              <Tooltip title="Delete" color="rgb(20, 72, 129)">
                <Button>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </span>
        </div>
      ),
    },
  ];

  const cancel = () => {};

  const PostData = (data) => {
    setLoader(true);
    const PostData = {
      name: data.facility_name,
      job_code: data.job_code,
    };
    dispatch(postAdhocFacility(PostData)).then((record) => {
      if (record.payload.success) {
        toast.success(record.payload.message);
        setLoader(false);
        setVisible(false);
        dispatch(getAllAdhocFacility());
      } else {
        toast.error(record.payload.message);
        setLoader(false);
        setVisible(false);
        dispatch(getAllAdhocFacility());
      }
    });
  };

  const EditData = (data) => {
    setEditLoader(true);
    const putData = {
      name: data.facility_name,
      job_code: data.job_code,
    };
    dispatch(putAdhocFacility(id, putData)).then((record) => {
      if (record.payload.success) {
        toast.success(record.payload.message);
        setEditLoader(false);
        setEditVisible(false);
        dispatch(getAllAdhocFacility()).then(() => {});
      } else {
        toast.error(record.payload.message);
        setEditLoader(false);
        setEditVisible(false);
        dispatch(getAllAdhocFacility()).then(() => {
          setLoading(false);
        });
      }
    });
  };

  return (
    <>
      <div>
        <Button className="style-btn" onClick={() => setVisible(true)} style={{ float: "right", marginBottom: "20px" }} icon={<PlusCircleOutlined />} shape="round" size="default">
          New Adhoc Facility
        </Button>
      </div>
      <Table loading={loading} columns={column} dataSource={AdhocFacilityList} />

      <Modal title="Add Adhoc Facility" centered visible={visible} footer={null} onCancel={() => setVisible(false)} width={400}>
        <Form onFinish={PostData} layout="vertical" form={form}>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="facility_name"
                className="btn-weekpicker"
                label="Facility Name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Facility Name.",
                  },
                ]}
              >
                <Input placeholder="Facility Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="job_code"
                className="btn-weekpicker"
                label="Job Code"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Job Code.",
                  },
                ]}
              >
                <Input placeholder="Job Code" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit" loading={loader}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal title="Edit Adhoc Facility" centered visible={editVisible} footer={null} onCancel={() => setEditVisible(false)} width={400}>
        <Form onFinish={EditData} initialValues={defaultValue} layout="vertical" form={form1}>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="facility_name"
                className="btn-weekpicker"
                label="Facility Name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Facility Name.",
                  },
                ]}
              >
                <Input placeholder="Facility Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="job_code"
                className="btn-weekpicker"
                label="Job Code"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Job Code.",
                  },
                ]}
              >
                <Input placeholder="Job Code" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit" loading={editLoader}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default memo(AdhocFacilityManagement);
