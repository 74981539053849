import { DeleteOutlined, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Divider, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from "antd";
import moment from "moment";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-week-picker/src/lib/calendar.css";
import { deleteExtraHours } from "redux/actions/deleteExtraHours.action";
import { getFacilityInfo } from "redux/actions/getAllFacilityList.action";
import { getExtraHoursList } from "redux/actions/getExtraHours.action";
import { getExtraHoursCategoryInfo } from "redux/actions/getExtraHoursCategory.action";
import { getServiceType } from "redux/actions/getServiceType.action";
import { postExtraHours } from "redux/actions/postExtraHours.action";
import { getApiArrayDetails, handleTableSort, isValidArray } from "../../../utils/MyMUtils";

const { Option } = Select;
const AnotherFormat = "DD-MM-YYYY";

moment.locale("en", {
  week: {
    dow: 1, /// Date offset
  },
});

const ExtraHoursForm = () => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [viewFacilityId, setViewFacilityID] = useState("");
  const [viewStartDate, setViewStartDate] = useState("");
  const [viewEndDate, setViewEndDate] = useState("");

  const dispatch = useDispatch();
  let searchInput = useRef(null);

  const ExtraHoursCategory = useSelector((state) => getApiArrayDetails(state.getExtraHoursCategoryList.data));
  const facilityData = useSelector((state) => getApiArrayDetails(state.getFacilityList.data));
  const serviceTypeList = useSelector((state) => getApiArrayDetails(state.ServiceType.data));
  const getExtraHours = useSelector((state) => getApiArrayDetails(state.getExtraHoursDetail.data));

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  useEffect(() => {
    //get extra hours category
    dispatch(getExtraHoursCategoryInfo());
    //get all facility
    dispatch(getFacilityInfo());
  }, []);

  const onChangeFacility = (value) => {
    dispatch(getServiceType(value));
  };
  const getCategoryList = () => {
    if (isValidArray(ExtraHoursCategory)) {
      return ExtraHoursCategory.map((category) => (
        <Option name="state_id" key={category.id} value={category.id}>
          {category.name}
        </Option>
      ));
    }
  };
  const getFacilityListDetails = () => {
    if (isValidArray(facilityData)) {
      return facilityData.map((facilityData) => (
        <Option name="facility_id" key={facilityData.facility_id} value={facilityData.facility_id}>
          {facilityData.facility_name}
        </Option>
      ));
    }
  };
  const getServiceTypeListDetails = () => {
    if (isValidArray(serviceTypeList)) {
      return serviceTypeList.map((type) => (
        <Option name="state_id" key={type.id} value={type.id}>
          {type.name}
        </Option>
      ));
    }
  };

  //-----Data table search functionality start-------
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: "#1a3353" ? "#1a3353" : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });
  //-----Data table search functionality end-------

  //---------view data start-------------//
  const column = [
    {
      title: "Category Name",
      dataIndex: "category_name",
      key: "category_name",
      width: "15%",
      ...getColumnSearchProps("category_name"),
      sorter: (a, b) => handleTableSort(a, b, "category_name"),
    },
    {
      title: "Service Type",
      dataIndex: "service_name",
      key: "service_name",
      width: "15%",
      ...getColumnSearchProps("service_name"),
    },
    {
      title: "Mon",
      dataIndex: "Mon",
      key: "Mon",
      width: "5%",
    },
    {
      title: "Tue",
      dataIndex: "Tue",
      key: "Tue",
      width: "5%",
    },
    {
      title: "Wed",
      dataIndex: "Wed",
      key: "Wed",
      width: "5%",
    },
    {
      title: "Thu",
      dataIndex: "Thu",
      key: "Thu",
      width: "5%",
    },
    {
      title: "Fri",
      dataIndex: "Fri",
      key: "Fri",
      width: "5%",
    },
    {
      title: "Sat",
      dataIndex: "Sat",
      key: "Sat",
      width: "5%",
    },
    {
      title: "Sun",
      dataIndex: "Sun",
      key: "Sun",
      width: "5%",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      width: "10%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "10%",
      render: (_, elm) => (
        <div>
          <span className="mr-3">
            <Popconfirm title="Are you sure to delete this data?" onConfirm={() => deleteFunction(elm)} onCancel={() => cancel} okText="Yes" cancelText="No">
              <Tooltip title="Delete" color="rgb(20, 72, 129)">
                <Button>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </span>
        </div>
      ),
    },
  ];

  const handleOnChangeFacility = (facility) => {
    setLoading(true);
    setViewFacilityID(facility);
    const PostData = {
      facility_id: facility,
      first_date: viewStartDate,
      last_date: viewEndDate,
    };
    dispatch(getExtraHoursList(PostData)).then((record) => {
      if (isValidArray(record.payload.result)) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  const weekPickerView = (value) => {
    setLoading(true);
    const start_date = moment(value).startOf("week").format(AnotherFormat);
    const end_date = moment(value).endOf("week").format(AnotherFormat);
    setViewStartDate(start_date);
    setViewEndDate(end_date);
    const PostData = {
      facility_id: viewFacilityId,
      first_date: start_date,
      last_date: end_date,
    };
    dispatch(getExtraHoursList(PostData)).then((record) => {
      if (isValidArray(record.payload.result)) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  const customWeekStartEndViewFormat = (value) => {
    return `${moment(value).startOf("week").format("DD-MM-YYYY")} ~ ${moment(value).endOf("week").format("DD-MM-YYYY")}`;
  };

  //---------view data end------------//

  //-----week picker for add start------//
  const weekPicker = (value) => {
    const start_date = moment(value).startOf("week").format(AnotherFormat);
    const end_date = moment(value).endOf("week").format(AnotherFormat);
    setStartDate(start_date);
    setEndDate(end_date);
  };

  const customWeekStartEndFormat = (value) => {
    return `${moment(value).startOf("week").format("DD-MM-YYYY")} ~ ${moment(value).endOf("week").format("DD-MM-YYYY")}`;
  };
  //-----week picker for add end------//

  //---------add Extra hours start----------//
  const PostData = useCallback((data) => {
    const dataArray = [];
    const dataObj = {
      category_id: data.category_id,
      service_type: data.service_type,
      notes: data.notes,
      mon: parseFloat(data.mon).toFixed(2) !== "NaN" ? parseFloat(data.mon).toFixed(2) : parseFloat(0).toFixed(2),
      tue: parseFloat(data.tue).toFixed(2) !== "NaN" ? parseFloat(data.tue).toFixed(2) : parseFloat(0).toFixed(2),
      wed: parseFloat(data.wed).toFixed(2) !== "NaN" ? parseFloat(data.wed).toFixed(2) : parseFloat(0).toFixed(2),
      thu: parseFloat(data.thu).toFixed(2) !== "NaN" ? parseFloat(data.thu).toFixed(2) : parseFloat(0).toFixed(2),
      fri: parseFloat(data.fri).toFixed(2) !== "NaN" ? parseFloat(data.fri).toFixed(2) : parseFloat(0).toFixed(2),
      sat: parseFloat(data.sat).toFixed(2) !== "NaN" ? parseFloat(data.sat).toFixed(2) : parseFloat(0).toFixed(2),
      sun: parseFloat(data.sun).toFixed(2) !== "NaN" ? parseFloat(data.sun).toFixed(2) : parseFloat(0).toFixed(2),
    };
    if (data.hours != undefined) {
      dataArray.push(dataObj, ...data.hours);
    } else {
      dataArray.push(dataObj);
    }
    const newData = {
      facility_id: data.facility_id,
      first_date: StartDate,
      last_date: EndDate,
      hours: dataArray,
    };
    dispatch(postExtraHours(newData)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
      } else {
        toast.error(record.payload.message);
      }
    });
    setVisible(false);
  });
  //---------add Extra hours end----------//

  //-------delete record start-----------------
  const deleteFunction = (elm) => {
    const newData = {
      facility_id: viewFacilityId,
      first_date: viewStartDate,
      last_date: viewEndDate,
      category_id: elm.category_id,
      service_type: elm.service_type,
    };
    const PostData = {
      facility_id: viewFacilityId,
      first_date: viewStartDate,
      last_date: viewEndDate,
    };
    dispatch(deleteExtraHours(newData)).then((record) => {
      if (record.payload.success == true) {
        toast.success(record.payload.message);
        dispatch(getExtraHoursList(PostData)).then((record) => {
          setLoading(true);
          if (isValidArray(record.payload.result)) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      } else {
        toast.error(record.payload.message);
        dispatch(getExtraHoursList(PostData)).then((record) => {
          setLoading(true);
          if (isValidArray(record.payload.result)) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      }
    });
  };
  const cancel = () => {};
  //-------delete record end-----------------

  return (
    <>
      <Form layout="horizontal" form={form2}>
        <Row gutter={24}>
          <Col span={5}></Col>
          <Col span={5}></Col>
          <Col span={5}>
            <Form.Item>
              <div>
                <Select allowClear onChange={handleOnChangeFacility} placeholder="Select Facility">
                  {getFacilityListDetails()}
                </Select>
              </div>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item>
              <div>
                <DatePicker onChange={weekPickerView} format={customWeekStartEndViewFormat} picker="week" />
              </div>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <div>
                <Button className="style-btn" onClick={() => setVisible(true)} icon={<PlusCircleOutlined />} shape="round" size="default">
                  Add Extra Hours
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table loading={loading} columns={column} rowKey="id" dataSource={getExtraHours} />

      <Modal title="Add Extra Hours" centered visible={visible} footer={null} onCancel={() => setVisible(false)} width={1300}>
        <Form onFinish={PostData} layout="vertical" form={form}>
          <Row gutter={24}>
            <Col md={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please Select Facility.",
                  },
                ]}
                name="facility_id"
                label="Select Facility"
              >
                <Select placeholder="Select Facility" onChange={onChangeFacility}>
                  {getFacilityListDetails()}
                </Select>
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please Select Week.",
                  },
                ]}
                name="first_dates"
                label="Select Week"
              >
                <DatePicker onChange={weekPicker} format={customWeekStartEndFormat} picker="week" />
              </Form.Item>
            </Col>
            <Col md={8}></Col>
          </Row>
          <Divider>Weekly Hours</Divider>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Card>
                <Row gutter={24} className="mr-4">
                  <Col gutter={24}>
                    <Row>
                      <Col className="mr-2">
                        <Form.Item
                          style={{ width: "150px" }}
                          label="Category"
                          name="category_id"
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <Select placeholder="Select Category">{getCategoryList()}</Select>
                        </Form.Item>
                      </Col>
                      <Col className="mr-2">
                        <Form.Item
                          style={{ width: "160px" }}
                          name="service_type"
                          label="Service Type"
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <Select placeholder="Select Service Type">{getServiceTypeListDetails()}</Select>
                        </Form.Item>
                      </Col>

                      <Col className="mr-2 lab">
                        <Form.Item name="mon" label="Mon">
                          <InputNumber step="0.00" stringMode placeholder="Mon" />
                        </Form.Item>
                      </Col>
                      <Col className="mr-2 lab">
                        <Form.Item name="tue" label="Tue">
                          <InputNumber step="0.00" placeholder="Tue" />
                        </Form.Item>
                      </Col>
                      <Col className="mr-2 lab">
                        <Form.Item name="wed" label="Wed">
                          <InputNumber step="0.00" placeholder="Wed" />
                        </Form.Item>
                      </Col>
                      <Col className="mr-2 lab">
                        <Form.Item name="thu" label="Thu">
                          <InputNumber step="0.00" placeholder="Thu" />
                        </Form.Item>
                      </Col>
                      <Col className="mr-2 lab">
                        <Form.Item name="fri" label="Fri">
                          <InputNumber step="0.00" placeholder="Fri" />
                        </Form.Item>
                      </Col>
                      <Col className="mr-2 lab">
                        <Form.Item name="sat" label="Sat">
                          <InputNumber step="0.00" placeholder="Sat" />
                        </Form.Item>
                      </Col>
                      <Col className="mr-2 lab">
                        <Form.Item label="Sun" name="sun">
                          <InputNumber step="0.00" placeholder="Sun" />
                        </Form.Item>
                      </Col>
                      <Col className="mr-2 lab">
                        <Form.Item label="Note" style={{ width: "150px" }} name="notes">
                          <Input placeholder="Note" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Form.List name="hours">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Space key={key} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, "category_id"]}
                            fieldKey={[fieldKey, "category_id"]}
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                            ]}
                            style={{ width: "150px" }}
                          >
                            <Select placeholder="Select Category">{getCategoryList()}</Select>
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[name, "service_type"]}
                            fieldKey={[fieldKey, "service_type"]}
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                            ]}
                            style={{ width: "160px" }}
                            disabled={!isValidArray(serviceTypeList) ? true : false}
                          >
                            <Select placeholder="Select Service Type">{getServiceTypeListDetails()}</Select>
                          </Form.Item>

                          <Form.Item {...restField} name={[name, "mon"]} fieldKey={[fieldKey, "mon"]}>
                            <InputNumber step="0.00" placeholder="Mon" />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, "tue"]} fieldKey={[fieldKey, "tue"]}>
                            <InputNumber step="0.00" placeholder="Tue" />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, "wed"]} fieldKey={[fieldKey, "wed"]}>
                            <InputNumber step="0.00" placeholder="Wed" />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, "thu"]} fieldKey={[fieldKey, "thu"]}>
                            <InputNumber step="0.00" placeholder="Thu" />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, "fri"]} fieldKey={[fieldKey, "fri"]}>
                            <InputNumber step="0.00" placeholder="Fri" />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, "sat"]} fieldKey={[fieldKey, "sat"]}>
                            <InputNumber step="0.00" placeholder="Sat" />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, "sun"]} fieldKey={[fieldKey, "sun"]}>
                            <InputNumber step="0.00" placeholder="Sun" />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, "notes"]} fieldKey={[fieldKey, "notes"]} style={{ width: "150px" }}>
                            <Input placeholder="Note" />
                          </Form.Item>

                          <Button onClick={() => remove(name)} icon={<DeleteOutlined />}></Button>
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusCircleOutlined />}>
                          Add
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Card>
            </Col>
          </Row>

          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit" loading={loader}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(ExtraHoursForm);
