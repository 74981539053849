import {
  BankOutlined,
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FieldTimeOutlined,
  LoginOutlined,
  ReadOutlined,
  SearchOutlined,
  ShopOutlined,
  SkinOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Select, Space, Steps, Table, Tooltip } from "antd";
import { CLEANING_AUDIT, EDUCATION_TOOLBOX, FACILITY_MANAGEMENT, LAUNDRY_AUDIT, PERIODICAL } from "MyMConstants";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteUser } from "redux/actions/deleteUser.action";
import { getExistStaffUserManagement } from "redux/actions/getExistStaffuserManagement.action";
import { getFacilitiesByManager } from "redux/actions/getFacilitiesByManager.action";
import { getUserWiseFacility } from "redux/actions/getUserWiseFacility.action";
import { postNewStaff } from "redux/actions/postNewStaff.action";
import { getApiArrayDetails, getApiSingleDetails, GetValidDataFromResponse, handleTableSort } from "../../../utils/MyMUtils";
import ExistingStep1 from "./existingStep1";
import ExistingStep2 from "./existingStep2";
import ExistingStep3 from "./existingStep3";

const { Option } = Select;
const { Step } = Steps;

const FooterButton = ({ onCancel }) => {
  return (
    <Fragment>
      <Button onClick={() => onCancel()}>Cancel</Button>
    </Fragment>
  );
};

const ExistingStaff = () => {
  let searchInput = useRef(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [title, setTitle] = useState();
  const [allData, setAllData] = useState([]);
  const [userData, setuserData] = useState("");
  const [userId, setUserId] = useState();
  const [data, setData] = useState();
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [allowToDelete, setAllowToDelete] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    facilityCount: "",
  });
  const [postData, setpostData] = useState({
    role: "",
    facility: [
      {
        facility_id: "",
        modules: [],
      },
    ],
  });
  const [formView] = Form.useForm();
  const dispatch = useDispatch();

  const getExistStaffUserManagementList = useSelector((state) => getApiArrayDetails(state.getExistStaffUserManagement.data));

  const getUserWiseFacilityDetails = useSelector((state) => getApiSingleDetails(state.getUserWiseFacility.data));

  useEffect(() => {
    dispatch(getExistStaffUserManagement());
  }, []);

  useEffect(() => {
    setCurrentStep(currentStep);
  }, [currentStep]);

  useEffect(() => {
    formView.resetFields();
  }, [visible]);

  useEffect(() => {
    setuserData(getUserWiseFacilityDetails);
  }, [getUserWiseFacilityDetails]);

  const showEditModal = (elm) => {
    setCurrentStep(0);
    setTitle(elm.user_name);
    dispatch(getUserWiseFacility(elm.user_id)).then((record) => {
      setAllData(record.payload.result);
    });
    setUserId(elm?.user_id);
    setIsModalVisible(true);
  };

  const showViewModal = (elm) => {
    dispatch(getUserWiseFacility(elm.user_id));
    setVisible(true);
  };
  const deleteFunction = (user_id) => {
    dispatch(deleteUser(user_id)).then((record) => {
      if (record?.payload?.success === true) {
        toast.success(record?.payload?.message);
        dispatch(getExistStaffUserManagement());
        setIsModalVisible(false);
      } else {
        toast.error(record?.payload?.message);
        dispatch(getExistStaffUserManagement());
        setIsModalVisible(false);
      }
    });
  };

  const SelectRole = (id) => {
    setpostData({ ...postData, role: id });
  };

  const SelectFacility = (FacilityArray) => {
    setpostData({ ...postData, facility: FacilityArray });
  };

  useEffect(() => {
    const newPostData = postData?.facility?.map((d) => {
      return {
        facility_id: d.facility_id,
        modules: d.modules?.map((m) => m.module_id || m),
      };
    });
    setData(newPostData);
  }, [postData]);

  const getCurrentComponent = (current) => {
    switch (current) {
      case 1:
        return <ExistingStep2 prev={prev} next={next} selectFacility={SelectFacility} collectData={postData} passData={allData} />;
      case 2:
        return (
          <ExistingStep3
            prev={prev}
            done={done}
            // getLoginData={SelectLogin}
            userData={userData}
            passData={allData}
            loader={loader}
          />
        );
      case 0:
      default:
        return (
          <ExistingStep1
            next={next}
            selectRole={SelectRole}
            // // roleData={postData}
            // passData={allData}
            userRol={allData}
            defaultValue={postData}
          />
        );
    }
  };

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const done = (values) => {
    setLoader(true);
    const details = {
      user_id: userId,
      first_name: title?.split(" ").slice(0, -1).join(" "),
      last_name: title?.split(" ").slice(-1).join(" "),
      user_name: values?.username,
      password: values?.password,
      role_id: postData?.role,
      facility: data,
    };
    dispatch(postNewStaff(details)).then((record) => {
      if (record?.payload?.success === true) {
        setLoader(false);
        toast.success(record?.payload?.message);
        setIsModalVisible(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setLoader(false);
        toast.error(record?.payload?.message);
        setIsModalVisible(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: "#1a3353" ? "#1a3353" : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleStaffDelete = ({ user_name, user_id }) => {
    dispatch(getFacilitiesByManager(user_id)).then((response) => {
      let { result } = GetValidDataFromResponse(response);

      try {
        if (result.length > 0) {
          setAllowToDelete(false);
          setDeleteModalStatus(true);
          setUserDetails({
            name: user_name,
            facilityCount: result.length,
          });
        } else if (result.length === 0) {
          Modal.confirm({
            title: "Are you sure you want to delete this staff ?",
            okText: "Delete",
            cancelText: "Cancel",
            onOk: () => deleteFunction(user_id),
            onCancel: () => handleModalCancelAction(() => setAllowToDelete(false)),
          });
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",
      width: "30%",
      ...getColumnSearchProps("user_name"),
      sorter: (a, b) => handleTableSort(a, b, "user_name"),
    },
    {
      title: "Role",
      dataIndex: "role_name",
      key: "role_name",
      width: "20%",
      filters: [
        {
          text: "Agency Staff",
          value: "Agency Staff",
        },
        {
          text: "Client",
          value: "client",
        },
        {
          text: "Facility Staff",
          value: "Facility Staff",
        },
        {
          text: "Operations Manager",
          value: "Operations Manager",
        },
        {
          text: "Staff",
          value: "Staff",
        },
      ],
      onFilter: (value, record) => record.role_name === value,
      filterSearch: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "50%",
      render: (_, elm, row) => (
        <div>
          <span className="mr-3">
            <Tooltip title="View" color="rgb(20, 72, 129)">
              <Button shape="round" icon={<EyeOutlined />} onClick={() => showViewModal(elm)}></Button>
            </Tooltip>
          </span>

          <span className="mr-3">
            <Tooltip title="Edit" color="rgb(20, 72, 129)">
              <Button shape="round" icon={<EditOutlined />} onClick={() => showEditModal(elm)}></Button>
            </Tooltip>
          </span>

          <span className="mr-3">
            {/* <Popconfirm
              title="Are you sure to delete this data?"
              onConfirm={() => deleteFunction(elm)}
              // onCancel={() => cancel}
              okText="Yes"
              cancelText="No"
            > */}
            <Tooltip title="Delete" color="rgb(20, 72, 129)">
              <Button onClick={() => handleStaffDelete(elm)} shape="round" icon={<DeleteOutlined />}></Button>
            </Tooltip>
            {/* </Popconfirm> */}
          </span>
        </div>
      ),
    },
  ];

  const handleModalCancelAction = (callback) => {
    setUserDetails({});
    setDeleteModalStatus(() => false);
    callback();
  };

  return (
    <>
      <Form layout="horizontal">
        <Table
          // loading={!isValidArray(getExistStaffUserManagementList)}
          columns={columns}
          dataSource={getExistStaffUserManagementList}
          rowKey="id"
        />
      </Form>

      <Modal title="View Allocated User Details" centered visible={visible} footer={null} onCancel={() => setVisible(false)} width={900}>
        <React.Fragment>
          <Form form={formView}>
            <div className="view-facility">
              <Row className="d-flex justify-content-between bg-desc">
                <Col span={10}>
                  <div className="view-title">Name:</div>
                </Col>
                <Col span={14} className="view-content">
                  {getUserWiseFacilityDetails?.first_name} {getUserWiseFacilityDetails?.last_name}
                </Col>
              </Row>
              <Row className="d-flex justify-content-between bg-desc">
                <Col span={10} className="view-title ">
                  Role:
                </Col>
                <Col span={14} className="view-content">
                  {getUserWiseFacilityDetails?.role_name}
                </Col>
              </Row>
              <Row className="d-flex justify-content-between bg-desc">
                <Col span={10} className="view-title ">
                  User Name:
                </Col>
                <Col span={14} className="view-content">
                  {getUserWiseFacilityDetails?.user_name}
                </Col>
              </Row>

              <Row className="mt-1 mb-2">
                <Col span={10}>
                  <div
                    className="view-title ml-2"
                    style={{
                      textDecoration: " underline",
                    }}
                  >
                    Facility
                  </div>
                </Col>
                <Col span={14}>
                  <div className="view-title" style={{ textDecoration: " underline" }}>
                    Module{" "}
                  </div>
                </Col>
              </Row>
              {getUserWiseFacilityDetails?.facility_data &&
                getUserWiseFacilityDetails?.facility_data.map((item) => {
                  return (
                    <>
                      <Row className="bg-desc">
                        <Col span={10} className="view-content">
                          <span className="fw-500">{item.facility_name}</span>
                        </Col>
                        {item?.modules &&
                          item?.modules.map((data) => {
                            return (
                              <>
                                <Col span={2}>
                                  <Tooltip title={data?.module_name} color="rgb(20, 72, 129)">
                                    {/* {data?.module_name === "Cleaning Audit" ? (
                                      <ClearOutlined
                                        style={{ fontSize: "22px" }}
                                      />
                                    ) : data?.module_name ===
                                      "Education Toolbox" ? (
                                      <ReadOutlined
                                        style={{ fontSize: "22px" }}
                                      />
                                    ) : data?.module_name ===
                                      "Facility Management" ? (
                                      <BankOutlined
                                        style={{ fontSize: "22px" }}
                                      />
                                    ) : (
                                      <SkinOutlined
                                        style={{ fontSize: "22px" }}
                                      />
                                    )} */}
                                    {data?.module_name === CLEANING_AUDIT && <ClearOutlined style={{ fontSize: "22px" }} />}
                                    {data?.module_name === EDUCATION_TOOLBOX && <ReadOutlined style={{ fontSize: "22px" }} />}
                                    {data?.module_name === FACILITY_MANAGEMENT && <BankOutlined style={{ fontSize: "22px" }} />}
                                    {data?.module_name === LAUNDRY_AUDIT && <SkinOutlined style={{ fontSize: "22px" }} />}
                                    {data?.module_name === PERIODICAL && <FieldTimeOutlined style={{ fontSize: "22px" }} />}
                                  </Tooltip>
                                </Col>
                              </>
                            );
                          })}
                      </Row>
                    </>
                  );
                })}
            </div>
          </Form>
        </React.Fragment>
      </Modal>
      <Modal title={title} centered visible={isModalVisible} footer={null} onCancel={() => setIsModalVisible(false)} width={1000}>
        <React.Fragment>
          <Steps current={currentStep} className="steps" responsive="532px">
            <Step title="Role" icon={<UserOutlined />} />
            <Step title="Facility" icon={<ShopOutlined />} />
            <Step title="Login" icon={<LoginOutlined />} />
          </Steps>
          {getCurrentComponent(currentStep)}
        </React.Fragment>
      </Modal>
      <Modal
        title="You can't delete this user"
        visible={deleteModalStatus}
        footer={<FooterButton onCancel={() => handleModalCancelAction(() => setAllowToDelete(false))} />}
        onCancel={() => handleModalCancelAction(() => setAllowToDelete(false))}
        width={520}
      >
        <p>
          <b>{userDetails.name}</b> is managing total <b>{userDetails.facilityCount}</b> facilities. So assign these facilities to other and then delete.
        </p>
      </Modal>

      {/* Delete Modal */}
    </>
  );
};

export default ExistingStaff;
