import { EyeOutlined, MailOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, message, Modal, Popover, Row, Select, Spin, Table, Tabs, Tooltip, Upload } from "antd";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { uploadCertificate } from "redux/actions/uploadCertificate.action";
import { uploadLabourHire } from "redux/actions/uploadLabourHire.action";
import { getPublicLiability } from "../../../redux/actions/publicLiability.action";
import { getApiArrayDetails, getPreSignedUrl, handleTableSort, isValidArray } from "../../../utils/MyMUtils";
import Email from "../mail";

import moment from "moment";
const dateFormat = "DD/MM/YYYY";
const AnotherFormat = "YYYY/MM/DD";

const { Option } = Select;
const { TabPane } = Tabs;

const PublicLiability = () => {
  const url = window.location.pathname;
  const [componentSize, setComponentSize] = useState("small");
  const [activeUrl, setActiveUrl] = useState("1");
  const [typeId, setTypeId] = useState("");
  const [stateId, setStateId] = useState("");
  const [uploadInfo, setUploadInfo] = useState({});
  const [visible, setVisible] = useState(false);
  const [visibleMail, setVisibleMail] = useState(false);
  const [form] = Form.useForm();

  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const PublicList = useSelector((state) => getApiArrayDetails(state.publicLiability.data));
  const fileLoadingState = useSelector((state) => state.fileLoadingReducer.fileLoadingState);

  useEffect(() => {
    setVisibleMail(false);
    return () => {};
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const setMail = (elm) => {
    setVisibleMail(elm.id);
    if (visibleMail === elm.id) {
      setVisibleMail(false);
    }
  };

  useEffect(() => {
    dispatch(getPublicLiability());
    return () => {};
  }, []);

  useEffect(() => {
    setActiveUrl((state) => {
      switch (url) {
        case "/app/public_liability":
          return "1";
        case "/app/work_cover":
          return "2";
        default:
          return "1";
      }
    });
    return () => {
      setActiveUrl("");
    };
  }, [url]);

  const closePopover = () => {
    setVisibleMail(false);
  };

  const getPublicLiabilityData = useCallback(() => {
    if (isValidArray(PublicList)) {
      return PublicList.filter((item) => item.type == "Public Liability").map((dataValue) => {
        return dataValue;
      });
    }
  }, [PublicList]);
  const setUploadModel = (elm) => {
    setVisible(true);
    setTypeId("1");
    setStateId(elm?.state_id);
  };
  const postUploadPdfFunction = (data) => {
    const abc = moment(data.expiry_date).format(AnotherFormat);
    dispatch(uploadCertificate(uploadInfo)).then((record) => {
      const uploadFinal = {
        type_id: typeId,
        state_id: stateId,
        expiry_date: abc,
        pdf_link: record.payload.result.result,
      };
      dispatch(uploadLabourHire(uploadFinal)).then((record) => {
        if (record.payload.result.success === true) {
          dispatch(getPublicLiability());
          toast.success(record.payload.result.message);
          setVisible(false);
        } else {
          toast.error(record.payload.result.message);
          setVisible(false);
        }
      });
    });
  };

  function onChange(value, dateString) {}

  function onOk(value) {}

  const uploadSettings = {
    accept: ".pdf",
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        message.error(`${file.name} is not a pdf file`);
      }
      //   uploadStatement(file.type !== "application/pdf" ? false : true);
      return false;
    },
    onChange: (info) => {
      let selectedFile = info.fileList;
      let file = null;
      let fileName;
      if (selectedFile.length > 0) {
        let fileToLoad = selectedFile[selectedFile.length - 1].originFileObj;
        fileName = fileToLoad.name;
        let reader = new FileReader();
        reader.onload = function (fileEvent) {
          file = fileEvent.target.result;
          let name = fileName.split(".");
          const uploadInfo = {
            certificate_type: "public-liability",
            filename: name[0],
            file: file,
          };
          setUploadInfo(uploadInfo);
          // return uploadInfo;
        };
        reader.readAsDataURL(fileToLoad);
      }
    },
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "type",
      key: "name",
      width: "50%",
      sorter: (a, b) => handleTableSort(a, b, "name"),
      // filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "50%",
      render: (_, elm, row) => (
        <div>
          <span className="mr-3">
            <Tooltip title="View" color="rgb(20, 72, 129)">
              {getPublicLiabilityData()
                .filter((item) => elm.state_id == item.state_id)
                .map((item) => {
                  return <Button onClick={() => getPreSignedUrl(item.pdf_link)}>{fileLoadingState !== item.pdf_link ? <EyeOutlined /> : <Spin size="small" />}</Button>;
                })}
            </Tooltip>
          </span>
          <span className="mr-3">
            {getPublicLiabilityData()
              .filter((item) => elm.state_id == item.state_id)
              .map((item) => {
                return item?.pdf_link ? (
                  <Popover title="Additional Emails" content={<Email passData={elm} visibleMailprop={closePopover} />} visible={elm.id === visibleMail} trigger="click">
                    <Tooltip title="Mail" color="rgb(20, 72, 129)">
                      <Button onClick={() => setMail(elm)}>
                        <MailOutlined />
                      </Button>
                    </Tooltip>
                  </Popover>
                ) : (
                  <Tooltip title="Mail" color="rgb(20, 72, 129)">
                    <Button disabled>
                      <MailOutlined style={{ color: "#00000040" }} />
                    </Button>
                  </Tooltip>
                );
              })}
          </span>
          <span>
            <Tooltip title="Upload" color="rgb(20, 72, 129)">
              <Button onClick={() => setUploadModel(elm)}>
                <UploadOutlined />
              </Button>
            </Tooltip>
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Form layout="horizontal" onValuesChange={onFormLayoutChange}>
        <Table loading={!isValidArray(getPublicLiabilityData())} columns={columns} dataSource={getPublicLiabilityData()} rowKey="id" />
      </Form>
      <Modal title="Upload" centered visible={visible} footer={null} onCancel={() => setVisible(false)} width={500}>
        <Form onFinish={postUploadPdfFunction} layout="vertical" form={form}>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item
                name="expiry_date"
                label="Expiry Date:"
                initialValue={moment(moment())}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Expiry Date",
                  },
                ]}
              >
                <DatePicker
                  // defaultValue={moment(moment(), dateFormat)}
                  format={dateFormat}
                  onChange={onChange}
                  onOk={onOk}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24}>
              <Form.Item name="file" label="File:">
                <Upload {...uploadSettings}>
                  {/* <Button onClick={() => policeCheckUpload(elm)}> */}
                  <Button onClick={() => setVisible(true)}>
                    <UploadOutlined />
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="justify-content-center">
            <Col md={24} style={{ textAlign: "center" }}>
              <Button htmlType="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(PublicLiability);
